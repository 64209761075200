import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";

import Nav from '../../components/Nav'
import FormHeader from '../../components/FormHeader';
import { Navigate } from 'react-router-dom';

const PalletNew = (props) => {
    let { peid } = useParams()

    const [berryKinds, setBerryKinds] = useState({status:0, v:[], error:null})
    const [crateKinds, setCrateKinds] = useState({status:0, v:[], error:null})
    const [errorMsg, setErrorMsg] = useState({status: 1, error:null})

    useEffect(() => {
        const onAttributesResponse = (data, error) => {
            if (error) {
                setErrorMsg({status:2, error:error})
            } else {
                setBerryKinds({status:1, v:data.berry_kinds ? data.berry_kinds : [], error:null})
                setCrateKinds({status:1, v:data.crate_kinds ? data.crate_kinds : [], error:null})
            }
        }
        props.backend.GetAttributes(onAttributesResponse, {attributes: ["berry_kinds", "crate_kinds"]})
    }, [props.backend])

    const onSubmit = (ev) => {
        ev.preventDefault()
        let fd = new FormData(ev.target)
        const bk = berryKinds.v.find(b => b.id === fd.get("berry_kind"))
        if (!bk) {
            document.getElementById("berry_kind").focus()
            return
        }
        const ck = crateKinds.v.find(c => c.id === fd.get("crate_kind"))
        if (!ck) {
            document.getElementById("crate_kind").focus()
            return
        }
        const harvest_date = document.getElementById("harvest_date").value
        if (!harvest_date || harvest_date === "") {
            document.getElementById("harvest_date").focus()
            return
        }
        const pallet_name = document.getElementById("pallet_name").value
        if (!pallet_name || pallet_name === "") {
            document.getElementById("pallet_name").focus()
            return
        }

        const num_crates = Number(document.getElementById("num_crates").value.replace(/,/g, '.'))
        if (!num_crates || isNaN(num_crates)) {
            document.getElementById("num_crates").focus()
            return
        }
        const pallet_weight = Number(document.getElementById("pallet_weight").value.replace(/,/g, '.'))
        if (!pallet_weight || isNaN(pallet_weight)) {
            document.getElementById("pallet_weight").focus()
            return
        }
        const gross_weight_on_entry = Number(document.getElementById("gross_weight_on_entry").value.replace(/,/g, '.'))
        if (!gross_weight_on_entry || isNaN(gross_weight_on_entry)) {
            document.getElementById("gross_weight_on_entry").focus()
            return
        }

        var net_weight_on_entry = gross_weight_on_entry - pallet_weight - (num_crates * ck.weight)
        const data = {
            berry_kind: bk,
            crate_kind: ck,
            name: document.getElementById("pallet_name").value,
            num_crates: num_crates,
            pallet_weight: Number(pallet_weight).toFixed(2),
            gross_weight_on_entry: Number(gross_weight_on_entry).toFixed(2),
            net_weight_on_entry: Number(net_weight_on_entry).toFixed(2),
            harvest_date: (new Date(harvest_date)).toJSON(),
            is_full: document.getElementById("is_full").value === "Da",
            description: document.getElementById("description").value,
            random_id: Math.floor((Math.random() * 1000000) + 1).toString()
        }
        setErrorMsg({status: 0, error:null})
        props.backend.PalletNew(onSaveResponse, peid, data)
    }

    const onSaveResponse = (_, error) => {
        if (error) {
            setErrorMsg({status: 1, error:error})
        } else {
            setErrorMsg({status: 2, error:null})
        }
    }

    const dateInputToday = () => {
        return (new Date()).toISOString().split("T")[0]
    }

    const generatePalletName = (domTarget) => {
        props.backend.GeneratePalletName((data, error) => {
            // responseConsumer
            if (!error) {
                domTarget.value = data.name && data.name !== "" ? data.name : ""
            }
        }, peid)
    }

    return (
        <>
        <Nav isMain={false} subtitle="Prijem" {...props} />
        {(berryKinds.status === 0 || crateKinds.status === 0 || errorMsg.status === 0) && <div className="loadingOverlay"></div>}
        {errorMsg.status === 2 && <Navigate to={"/entries/"+peid} />}
        {errorMsg.status === 1 && berryKinds.status === 1 && crateKinds.status === 1 &&
        <>
            <div id="mainInput" className="inputForm">
            <FormHeader {...props} title="Novi Prijem" />
            <form method='POST' onSubmit={onSubmit}>
                <label htmlFor="berry_kind">Sorta:
                <select name="berry_kind" id="berry_kind" defaultValue="--">
                    <option value="--">--</option>
                    {berryKinds.v.map(bk => {return (
                        <option key={bk.id} id={bk.id} value={bk.id}>{bk.name}</option>
                    )})}
                </select>
                </label>
                <label htmlFor="crate_kind">Tip gajbice:
                <select name="crate_kind" id="crate_kind" defaultValue="--">
                    <option value="--">--</option>
                    {crateKinds.v.map(ck => {return (
                        <option key={ck.id} id={ck.id} value={ck.id}>{ck.name}</option>
                    )})}
                </select>
                </label>
                <label htmlFor="harvest_date">Datum berbe:
                    <input id="harvest_date" type="date" className="textinput" name="harvest_date" defaultValue={dateInputToday()} min="2023-01-01" max="2033-12-31" />
                </label>
                <div className="mergedLine">
                    <label htmlFor="pallet_name">Oznaka palete: 
                        <input id="pallet_name" type="text" className="textinput" name="pallet_name" placeholder="Unesi oznaku palete" />
                    </label>
                    <label htmlFor="generate_link">&nbsp; 
                        <p className='vertalign link' onClick={(ev) => generatePalletName(document.getElementById("pallet_name"))}>Generiši oznaku</p>
                    </label>
                </div>

                <label htmlFor="num_crates">Broj gajbica na paleti:
                    <input id="num_crates" type="number" defaultValue="0" className="textinput" name="num_crates" placeholder="Unesi broj gajbica" />
                </label>
                <label htmlFor="pallet_weight">Težina osnovne palete (kg):
                    <input id="pallet_weight" type="number" defaultValue="0" className="textinput" name="pallet_weight" placeholder="Unesi težinu" />
                </label>
                <label htmlFor="gross_weight_on_entry">Bruto Težina (kg):
                    <input id="gross_weight_on_entry" type="number" defaultValue="0" className="textinput" name="gross_weight_on_entry" placeholder="Unesi težinu" />
                </label>
                <label htmlFor="is_full">Da li je paleta puna?
                <select name="is_full" id="is_full" defaultValue="Da">
                    <option value="Da">Da (izmene nisu dozvoljene)</option>
                    <option value="Ne">Ne</option>
                </select>
                </label>
                <label htmlFor="description">Napomena:
                    <input id="description" type="text" className="textinput" name="description" />
                </label>
                
                {errorMsg.status === 1 && errorMsg.error && <div className="submitLine error">{errorMsg.error}</div>}
                <div className="submitLine">
                <input type="submit" className="button" name="submitForm" value="Snimi" />
                </div>
            </form>
            </div>
        </>
        }
        </>
    )
}

export default PalletNew
