import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { BsPencil } from 'react-icons/bs'

import Nav from '../../components/Nav'
import ActionButton from '../../components/ActionButton';

function DeliveryList(props) {
    const pencilIcon = <BsPencil size="14"/>
    const [deliveries, setDeliveries] = useState({status: 0, v:[], error:null})

    const dateHead = (date) => {
        var d = new Date(date)
        var options = { day: '2-digit', month: 'numeric', year: 'numeric'};
        return d.toLocaleDateString('sr-latn', options)
    }

    const printTime = (date) => {
        var d = new Date(date)
        var options = { hour:'2-digit', minute: '2-digit'};
        return d.toLocaleTimeString('sr-latn', options)
    }

    useEffect(() => {
        const onListResponse = (data, error) => {
            if (error) {
                setDeliveries({status: 1, v:[], error: error})
            } else {
                setDeliveries({status: 1, v:data.deliveries ? data.deliveries : [], error: null})
            }
        }
        props.backend.DeliveryList(onListResponse)
    }, [props.backend])
    
    return (
        <>
        <Nav isMain={false} subtitle="Isporuke" {...props} />
        {deliveries.status === 0 && <div className="loadingOverlay"></div>}
        {deliveries.status === 1 && 
        <div className="content">
            <p className="contentSubtitle">Isporuke iz hladnjače</p><br />
            {props.user.system_role === "admin" &&
            <div className="filterMenu">
            <Link to="/deliveries/new">
                <ActionButton title="Kreiraj" icon={pencilIcon} />
            </Link>
            </div>
            }
            <div className="hscrollcontainer sticky">
            <table>
                <thead>
                <tr>
                    <th align="center" >Uneto</th>
                    <th align="center" >Kupac</th>
                    <th align="center" >Oznaka</th>
                    <th align="center" >Broj<br />paleta</th>
                    <th align="center" >Bruto<br />( kg )</th>
                    <th align="center" >Neto<br />( kg )</th>
                    <th align="center" >Status</th>
                    <th align="center" >&nbsp; - &nbsp;</th>
                </tr>
                </thead>
                <tbody>
                {deliveries.v.length === 0 &&
                    <tr><td colSpan="5">Nema unetih isporuka</td></tr>
                }
                {deliveries.v.length > 0 && deliveries.v.map(d => { 
                    return (
                        <tr key={d.id}>
                        <td align="center">{dateHead(d.open_date)}<br />{printTime(d.open_date)}</td>
                        <td align="center">{d.buyer.name}</td>
                        <td align="center">{d.name}</td>
                        <td align="center">{d.num_pallets}</td>
                        <td align="right">{Number(d.gross_weight).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                        <td align="right">{Number(d.net_weight).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                        <td align="center">{d.status.name}</td>
                        <td align="center"><Link to={"/deliveries/"+ d.id}>Detalji</Link></td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
            </div>    
        </div>
        }
        </>
    )
}

export default DeliveryList
