import React from 'react'
import { Link } from 'react-router-dom'
import { AiOutlineMenuUnfold } from 'react-icons/ai'
import LogoBox from './LogoBox'

const Logo = ({isMain, farmName, withLink}) => {
    if (isMain) {
        return (
            <div className="logo">
                <LogoBox />
            </div>           
        )
    }

    if (withLink && withLink !== "") {
        return (
            <div className="logo">
                <Link to={withLink}><AiOutlineMenuUnfold size="24" /></Link>
            </div>
        )
    }
    return (
        <div className="logo">
            <Link to="/home"><AiOutlineMenuUnfold size="24" /></Link>
        </div>
    )
}

export default Logo