import React from 'react'
import { FaUser } from "react-icons/fa";
import Logo from '../components/Logo'
import { Link } from 'react-router-dom';

const Nav = (props) => {
    if (props.isMain === undefined) {
        props.isMain = true;
    }

    const toggleUserMenu = () => {
        let menu = document.querySelector('.header__account__navlinks')
        menu.classList.toggle('blockdisplaytoggle')
    }

    const doLogout = (ev) => {
        ev.preventDefault()
        props.authService.Logout(props.clearUserProfile)
    }

    const switchFarm = (ev) => {
        ev.preventDefault()
        const newFarmID = [].filter.call(ev.target.options, opt => opt.selected)[0].value
        props.backend.SwitchFarm((_, error) => {
            if (!error) window.location = "/";
        }, newFarmID)
    }

    const subDisplay = () => {
        if (props.user.other_farms && props.user.other_farms.length > 0) {
            return (
                <select className="farmSelect" name="farmSelect" onChange={switchFarm}>
                    <option value={props.user.farm_id}>{props.user.farm_name}</option>
                    {props.user.other_farms.map(of => {return(
                        <option key={of[0]} value={of[0]}>{of[1]}</option>
                    )})}
                </select>
            )
        }
        return props.subtitle !== undefined ? <h5>{props.subtitle}</h5> : <h5>{props.user.farm_name}</h5>
    }

    return (
        <header>
            <Logo isMain={props.isMain} farmName={props.user.farm_name} withLink={props.withLink}/>
            {subDisplay()}
            <div className="header__account" onClick={toggleUserMenu}>
                <h5 className="header__account__name">{props.user.first_name} {props.user.last_name}</h5>
                <FaUser color="white" size="25px"/>
            </div>

            <div className="header__account__navlinks">
                <ul>
                    <li>
                        <Link to="/accountsettings">Podešavanja</Link>
                    </li>
                    <li>
                        <a href="/#logout" onClick={doLogout}>Odjava</a>
                    </li>
                </ul>
            </div>
            
        </header>
    )
}

export default Nav
