import React, { useState } from 'react'

import Nav from '../../components/Nav'
import FormHeader from '../../components/FormHeader';
import { Navigate } from 'react-router-dom';

const BuyerNew = (props) => {
    const [errorMsg, setErrorMsg] = useState({status: 1, error:null})
    const onSubmit = (ev) => {
        ev.preventDefault()
        let fd = new FormData(ev.target)
        const name = fd.get("name")
        if (!name || name === "") {
            document.getElementById("name").focus()
            return;
        }
        const code = fd.get("code")
        if (!code || code === "" || code.length > 4) {
            document.getElementById("code").focus()
            return
        }

        const data = {
            name: name,
            code: code.toUpperCase(),
        }

        setErrorMsg({status: 0, error:null})
        props.backend.BuyerNew(onSaveResponse, data)
    }

    const onSaveResponse = (data, error) => {
        if (error) {
            setErrorMsg({status: 1, error:error})
        } else {
            setErrorMsg({status: 2, error:null})
        }
    }

    return (
        <>
        <Nav isMain={false} subtitle="Kupci" {...props} />
        {(errorMsg.status === 0) && <div className="loadingOverlay"></div>}
        {errorMsg.status === 2 && <Navigate to="/buyers" />}
        {errorMsg.status === 1 && 
        <>
            <div className="inputForm">
            <FormHeader {...props} title="Novi Kupac" />
            <form method='POST' onSubmit={onSubmit}>
                <label htmlFor="name">Naziv:
                    <input id="name" type="text" className="textinput" name="name" />
                </label>
                <label htmlFor="code">Kratka oznaka:
                    <input id="code" type="text" className="textinput" name="code" />
                </label>
                {errorMsg.status === 1 && errorMsg.error && <div className="submitLine error">{errorMsg.error}</div>}
                <div className="submitLine">
                <input type="submit" className="button" name="submitForm" value="Snimi" />
                </div>
            </form>
            </div>
        </>
        }
        </>
    )
}

export default BuyerNew
