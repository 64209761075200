import React from 'react'
import { useNavigate } from 'react-router-dom';
import { FaArrowAltCircleLeft } from 'react-icons/fa'

const BackButton = (props) => {
    const navigate = useNavigate();
    return (
        <div className="backButton" onClick={(ev) => props.linkTo ? props.linkTo(ev) : navigate(-1)}>
            <FaArrowAltCircleLeft size="24" /> &nbsp;Nazad
        </div>
    )
}

export default BackButton