import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import PrintIcon from '@mui/icons-material/Print';
import { Link } from 'react-router-dom'

import Nav from '../../components/Nav'
import ActionButton from '../../components/ActionButton';
import { BsPencil } from 'react-icons/bs';

function PalletEntryView(props) {
    let { peid } = useParams()
    const [palletEntry, setPalletEntry] = useState({status: 0, v:{}, error:null})
    const [docUploading, setDocUploading] = useState(null)
    const [loadTs, setLoadTs] = useState(0)
    const pencilIcon = <BsPencil size="14"/>

    const dateHead = (date) => {
        var d = new Date(date)
        var options = { day: '2-digit', month: '2-digit', year: 'numeric'};
        return d.toLocaleDateString('sr-latn', options)
    }

    const printTime = (date) => {
        var d = new Date(date)
        var options = { hour:'2-digit', minute: '2-digit'};
        return d.toLocaleTimeString('sr-latn', options)
    }

    useEffect(() => {
        setLoadTs(Date.now())
        const onGetResponse = (data, error) => {
            if (error) {
                setPalletEntry({status: 1, v:{}, error: error})
            } else {
                setPalletEntry({status: 1, v:data, error: null})
            }
        }
        props.backend.PalletEntryGet(onGetResponse, peid)
    }, [props.backend])

    const onInputFileChange = (ev) => {
        var fd = new FormData();
        fd.append("file", ev.target.files[0])
        setDocUploading(1)
        setPalletEntry({status:0, v:palletEntry.v, error:null})

        props.backend.EntryDocUpload(() => {
            setDocUploading(null)

            props.backend.PalletEntryGet((data, error) => {
                if (error) {
                    setPalletEntry({status: 1, v:{}, error: error})
                } else {
                    setPalletEntry({status: 1, v:data, error: null})
                }
            }, peid)
        }, fd, peid)
    }

    const deletePallet = (pid) => {
        setPalletEntry({status:0, v:palletEntry.v, error:null})
        props.backend.PalletDelete((data, error) => {
            if (error) {
                setPalletEntry({status:1, v:palletEntry.v, error:error})
            } else {
                setPalletEntry({status:1, v:data, error:null})
            }
        }, peid, pid)
    }

    return (
        <>
        <Nav isMain={false} withLink={"/entries/"} subtitle="Prijem" {...props} />
        
        {(palletEntry.status === 0 || docUploading === 1 || loadTs === 0) && <div className="loadingOverlay"></div>}
        {palletEntry.status === 1 && !docUploading && loadTs !== 0 &&
        <div className="content">
            <div className='headsumcontainer'>
                <table>
                    <tbody>
                    <tr>
                        <td align="left">Zasad:</td>
                        <td align="right">{palletEntry.v.farm.name}</td>
                    </tr>
                    <tr>
                        <td align="left">Datum prijema:</td>
                        <td align="right">{dateHead(palletEntry.v.created_at)}</td>
                    </tr>
                    <tr>
                        <td align="left">Status:</td>
                        {palletEntry.v.status === "U Pripremi" && 
                            <td align="right" className='incomplete'>
                                {props.user.system_role === "admin" ? <Link to={"/entries/"+peid+"/edit"}>{palletEntry.v.status}</Link> : palletEntry.v.status}
                            </td>
                        }
                        {palletEntry.v.status === "Primljeno" && <td align="right" className='complete'>{palletEntry.v.status}</td>}
                    </tr>
                    {palletEntry.v.status === "Primljeno" &&
                    <tr>
                        <td colSpan={2} align='center'>
                            <iframe title={"PalletEntry - "+peid+"_"+loadTs} id={"printpeframe-"+peid+"_"+loadTs} name={"printpeframe-"+peid+"_"+loadTs} className='printonly' src={"/print/entry/"+peid+"?ts="+loadTs} />
                            <span  style={{color: "blue", cursor: "pointer"}} onClick={(ev) => {
                                ev.preventDefault()
                                window.frames["printpeframe-"+peid+"_"+loadTs].focus();
                                window.frames["printpeframe-"+peid+"_"+loadTs].print();
                            }}>Prijemni list <PrintIcon sx={{ fontSize: "16px", color:"blue" }} /></span>
                        </td>
                    </tr>
                    }  
                    </tbody>
                </table>
            </div>
            <p className="contentSubtitle">Priložena dokumenta:</p><br />
            <div className="hscrollcontainer" style={{maxWidth: "300px"}}>
                <table style={{width:"100%"}} id="inputArticles">
                    <thead>
                        <tr>
                            <td align="center">Datum</td>
                            <td align="center">Prilog</td>
                        </tr>
                    </thead>
                    <tbody>
                    {(!palletEntry.v.documents || palletEntry.v.documents.length === 0) &&
                    <tr><td colSpan="2">Nema priloženih dokumenata</td></tr>
                    }
                    {palletEntry.v.documents && palletEntry.v.documents.length > 0 && palletEntry.v.documents.map((d, idx) => {return (
                        <tr key={d.id}>
                            <td align="center">{dateHead(d.created_at)}<br />{printTime(d.created_at)}</td>
                            <td align="center"><a href={"/api/documents/entries/"+peid+"/"+d.id}>Prilog-{idx+1}</a></td>
                        </tr>
                    )})}
                    </tbody>
                </table>
            </div>
            {props.user.system_role === "admin" && 
            <div className='inputForm'>
            <label htmlFor="document">Dodaj dokument:
                <input type="file" id="document" name="document" onChange={onInputFileChange} />
            </label>
            </div>
            }
            
            <hr />
            <p className="contentSubtitle">Palete:</p><br />
            {palletEntry.v.status === "U Pripremi" && props.user.system_role === "admin" &&
                <div className="filterMenu">
                <Link to={"/entries/"+peid+"/new"}>
                    <ActionButton title="Dodaj" icon={pencilIcon} />
                </Link>
                </div>
            }
            <div className="hscrollcontainer sticky">
            <table>
                <thead>
                <tr>
                    <th align="center" >Uneto</th>
                    <th align="center" >Datum<br />berbe</th>
                    <th align="center" >Oznaka</th>
                    <th align="center" >Sorta</th>
                    <th align="center" >Težina<br />palete</th>
                    <th align="center" >Tip<br />Gajbica</th>
                    <th align="center" >Broj<br />Gajbica</th>
                    <th align="center" >Bruto<br />( kg )</th>
                    <th align="center" >Neto<br />( kg )</th>
                    <th align="center" >Status</th>
                    <th align="center" >Napomena</th>
                    <th align="center" >Stiker</th>
                    <th align="center" >&nbsp; - &nbsp;</th>
                </tr>
                </thead>
                <tbody>
                {(!palletEntry.v.pallets || palletEntry.v.pallets.length === 0) &&
                    <tr><td colSpan="13">Nema unetih paleta</td></tr>
                }
                {palletEntry.v.pallets && palletEntry.v.pallets.length > 0 && palletEntry.v.pallets.map(p => { 
                    return (
                        <tr key={p.id}>
                        <td align="center">{dateHead(p.entry_date)}<br />{printTime(p.entry_date)}</td>
                        <td align="center">{dateHead(p.harvest_date)}</td>
                        <td align="center">{p.name}</td>
                        <td align="center">{p.berry_kind.name}</td>
                        <td align="right">{Number(p.pallet_weight).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                        <td align="center">{p.crate_kind.name}</td>
                        <td align="center">{p.num_crates}</td>
                        <td align="right">{Number(p.gross_weight_on_entry).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                        <td align="right">{Number(p.net_weight_on_entry).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                        <td align="center">{p.status.name}</td>
                        <td align="center">{p.description}</td>
                        <td align="center">
                            <span style={{color: "blue", cursor: "pointer"}}>
                                <iframe title={"PalletSticker - "+p.name+"_"+loadTs} id={"printframe-"+p.id+"_"+loadTs} name={"printframe-"+p.id+"_"+loadTs} className='printonly' src={"/print/pallet/"+p.id+"/0?ts="+loadTs} />
                                <PrintIcon sx={{ fontSize: "16px", color:"blue" }} onClick={(ev) => {
                                    ev.preventDefault()
                                    window.frames["printframe-"+p.id+"_"+loadTs].focus();
                                    window.frames["printframe-"+p.id+"_"+loadTs].print();
                                }} />
                            </span>
                        </td>
                        {palletEntry.v.status === "U Pripremi" && props.user.system_role !== "admin" && <td align="center">-</td>}
                        {palletEntry.v.status === "U Pripremi" && props.user.system_role === "admin" &&
                            <td align="center"><Link to={"/entries/"+peid+"/"+p.id+"/remove"} onClick={(ev) => {ev.preventDefault(); deletePallet(p.id)}}>Obriši</Link></td>
                        }
                        {palletEntry.v.status === "Primljeno" &&
                            <td align="center">
                                {!p.is_full && p.status.name === "NA STANJU" && props.user.system_role === "admin" && <Link to={"/pallets/edit/" + peid + "/" + p.id}>Izmeni</Link>}
                                {(p.is_full || props.user.system_role !== "admin") && "-"}
                            </td>
                        }
                        </tr>
                    )
                })}
                </tbody>
            </table>
            </div>    
        </div>
        }
        </>
    )
}

export default PalletEntryView
