import React from 'react'
import { useState, useEffect, useMemo } from 'react'
import { Routes, Route } from "react-router-dom";
import AuthService from './services/AuthService'
import Backend from './Backend'
import InitLoading from './pages/InitLoading'
import Login from './Login'
import Landing from './Landing'

function App() {
    const backend = useMemo(() => new Backend(), []);
    const authService = useMemo(() => new AuthService(backend), [backend])

    const [userProfile, setUserProfile] = useState(null)
    const clearUserProfile = () => setUserProfile({})

    useEffect(() => {
        const onProfileResponse = (data, error) => {
            if (error) {
                setUserProfile({})
            } else {
                setUserProfile(data)
            }
        }
        backend.GetUserProfile(onProfileResponse)
    }, [backend])

    const reloadProfile = () => {
        backend.GetUserProfile((data, error) => {
            if (error) {
                setUserProfile({})
            } else {
                setUserProfile(data)
            }
        })
    }

    const onLoginSuccess = () => {
        backend.GetUserProfile(setUserProfile)
    }

    return (
        <Routes>
            <Route path='*' element={
              !userProfile
              ? <InitLoading />
              : !userProfile.email
                  ? <Login backend={backend} onLoginSuccess={onLoginSuccess} />
                  : <Landing user={userProfile} authService={authService} clearUserProfile={clearUserProfile} backend={backend} reloadProfile={reloadProfile} />
            } />
        </Routes>
    )
}

export default App;
