import React from 'react'

const ActionButton = ({icon, title, onClick}) => {
    return (
        <div className="actionButton" onClick={onClick}>
            {icon}&nbsp; {title}
        </div>
    )
}

export default ActionButton