import React, { useEffect, useState } from 'react'

import Nav from '../../components/Nav'
import StockMenu from '../../components/StockMenu'

function StockWaterLoss(props) {
    const [errorMsg, setErrorMsg] = useState({status: 1, error:null})
    const [waterLoss, setWaterLoss] = useState({status: 0, v:[], error:null})

    useEffect(() => {
        const onListResponse = (data, error) => {
            if (error) {
                setErrorMsg({status:1, error:error})
            } else {
                setWaterLoss({status:1, v: data.water_loss_list ? data.water_loss_list : [], error:null})
            }
        }
        props.backend.WaterLossList(onListResponse)
    }, [props.backend])

    return (
        <>
        <Nav isMain={false} subtitle="Izveštaji" {...props} />
        <StockMenu {...props} section="waterloss" />
        
        {waterLoss.status === 0 && <div className="loadingOverlay"></div>}
        {waterLoss.status === 1 && !errorMsg.error && 
        <div className="content">
            <p className="contentSubtitle">Kalo po zasadu:</p><br />
            <div className="hscrollcontainer">
            <table>
                <thead>
                <tr>
                    <th align="center" >Zasad</th>
                    <th align="center" >Broj<br />Paleta</th>
                    <th align="center" >Neto-1<br />( kg )</th>
                    <th align="center" >Neto-2<br />( kg )</th>
                    <th align="center" >Kalo<br />( kg )</th>
                    <th align="center" >Kalo<br />( % )</th>
                </tr>
                </thead>
                <tbody>
                {waterLoss.v.length === 0 &&
                    <tr><td colSpan="5">Nema zabeleženih izmena</td></tr>
                }
                {waterLoss.v.length > 0 && waterLoss.v.map((wl, idx) => {
                    return (
                        <tr key={idx}>
                        <td align="center">{wl.farm.name}</td>
                        <td align="center">{wl.num_pallets}</td>
                        <td align="right">{wl.net_weight_on_entry.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                        <td align="right">{wl.net_weight_on_exit.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                        <td align="right">{wl.lost_weight.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                        <td align="center">{wl.lost_weight_pct.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                        
                        {/*<td align="right">{e.summary.gross_weight.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})} &nbsp;</td>*/}
                        </tr>
                    )
                })}
                </tbody>
            </table>
            </div>
        </div>
        }
        </>
    )
}

export default StockWaterLoss
