import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import Nav from '../../components/Nav'
import FormHeader from '../../components/FormHeader';
import { Navigate } from 'react-router-dom';

const DeliveryEdit = (props) => {
    let { did } = useParams()
    const [delivery, setDelivery] = useState({status:0, v:{}, error:null})
    const [statuses, setStatuses] = useState({status:0, v:[], error:null})
    const [errorMsg, setErrorMsg] = useState({status: 1, error:null})


    useEffect(() => {
        const onGetResponse = (data, error) => { 
            if (error) {
                setDelivery({status:2, v:{}, error:error})
            } else {
                setDelivery({status:1, v:data, error:null})
            }
        }
        const onAttributesResponse = (data, error) => { 
            if (error) {
                setStatuses({status:2, v:[], error:error})
            } else {
                setStatuses({status:1, v:data.delivery_statuses ? data.delivery_statuses : [], error:null})
            }
        }
        props.backend.DeliveryGet(onGetResponse, did)
        props.backend.GetAttributes(onAttributesResponse, {attributes: ["delivery_statuses"]})
    }, [props.backend])

    const onSubmit = (ev) => {
        ev.preventDefault()

        const data = {
            status: {id: document.getElementById("status_id").value},
        }
        setErrorMsg({status: 0, error:null})
        props.backend.DeliveryUpdateStatus(onSaveResponse, did, data)
    }

    const onSaveResponse = (data, error) => {
        if (error) {
            setErrorMsg({status: 1, error:error})
        } else {
            setErrorMsg({status: 1, error:null})
            setDelivery({status: 2, v:delivery.v, error:null})
        }
    }

    return (
        <>
        <Nav isMain={false} subtitle="Isporuke" {...props} />
        {(delivery.status === 0 || errorMsg.status === 0) && <div className="loadingOverlay"></div>}
        {(delivery.status === 2 || statuses.status === 2) && <Navigate to={"/deliveries/"+ did} />}
        {delivery.status === 1 && statuses.status === 1 && 
        <>
            <div id="mainInput" className="inputForm">
            <FormHeader {...props} title="Izmena statusa isporuke" />
            <form method='POST' onSubmit={onSubmit}>
                <label htmlFor="name">Oznaka:
                <input id="name" type="text" className="textinput" name="name" disabled defaultValue={delivery.v.name} />
                </label>

                <label htmlFor="status_id">Novi Status Isporuke:
                <select name="status_id" id="status_id" defaultValue={delivery.v.status.id}>
                    {statuses.v.map(s => {return (
                        <option key={s.id} id={s.id} value={s.id}>{s.name}</option>
                    )})}
                </select>
                </label>

                {errorMsg.status === 1 && errorMsg.error && <div className="submitLine error">{errorMsg.error}</div>}
                <div className="submitLine">
                <input type="submit" className="button" name="submitForm" value="Snimi" />
                </div>
            </form>
            </div>
        </>
        }
        </>
    )
}

export default DeliveryEdit
