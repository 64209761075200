import React, { useState, useEffect } from 'react'

import Nav from '../../components/Nav'
import FormHeader from '../../components/FormHeader';
import { Navigate } from 'react-router-dom';
import { BsPlusCircle, BsDashCircle } from 'react-icons/bs';

const DeliveryNew = (props) => {
    const [buyers, setBuyers] = useState({status:0, v:[], error:null})
    const [berryKinds, setBerryKinds] = useState({status:0, v:[], error:null})
    const [crateKinds, setCrateKinds] = useState({status:0, v:[], error:null})
    const [newDid, setNewDid] = useState(null)

    const [plans, setPlans] = useState({v:[]})
    const [errorMsg, setErrorMsg] = useState({status: 1, error:null})


    useEffect(() => {
        const onAttributesResponse = (data, error) => {
            if (error) {
                setBuyers({status:2, v:[], error:error})
            } else {
                setBuyers({status:1, v:data.buyers ? data.buyers : [], error:null})
                setBerryKinds({status:1, v:data.berry_kinds ? data.berry_kinds : [], error:null})
                setCrateKinds({status:1, v:data.crate_kinds ? data.crate_kinds : [], error:null})
            }
        }
        props.backend.GetAttributes(onAttributesResponse, {attributes: ["berry_kinds", "crate_kinds", "buyers"]})
    }, [props.backend])

    const onSubmit = (ev) => {
        ev.preventDefault()
        let fd = new FormData(ev.target)

        if (plans.v.length === 0) {
            setErrorMsg({status: 1, error:"Morate dodati bar jednu sortu"})
            return
        }

        const data = {
            buyer: {id: fd.get("buyer_id")},
            description: fd.get("description"),
            selection_method: fd.get("selection_method"),
            max_num_pallets: Number(fd.get("max_num_pallets")),
            plans: plans.v,
        }

        setErrorMsg({status: 0, error:null})
        props.backend.DeliveryNew(onSaveResponse, data)
    }

    const onSaveResponse = (data, error) => {
        if (error) {
            setErrorMsg({status: 1, error:error})
        } else {
            if (data.id && data.id !== "") {
                setNewDid(data.id)
            } else {
                setErrorMsg({status: 1, error:null})
                setBuyers({status: 2, v:buyers.v, error:null})
            }
        }
    }

    const switchPlanMainInput = (ev) => {
        ev.preventDefault()
        document.getElementById("mainInput").classList.toggle("nodisplay")
        document.getElementById("planInput").classList.toggle("nodisplay")
    }

    const removePlan = (plan) => {
        var items = plans.v.filter(p => p !== plan)
        setPlans({v:items})
    }

    const addPlan = (data) => {
        var items = plans.v
        items.push(data)
        setPlans({v:items})
        // TODO deduplicate
    }

    const PlanInput = ({appendPlan}) => {
        const [selectedBerryKind, setSelectedBerryKind] = useState(null)

        const updateSelectedBerryKind = (ev) => {
            ev.preventDefault()
            const sb = ev.target;
            const berryKindID = [].filter.call(sb.options, opt => opt.selected)[0].value
            if (berryKindID === "--") {
                setSelectedBerryKind(null)
            } else {
                const bk = berryKinds.v.filter(p => p.id === berryKindID)[0]
                setSelectedBerryKind(bk)
            }
        }

        const add = (ev) => {
            ev.preventDefault()
            
            const bk = selectedBerryKind
            if (!bk) {
                document.getElementById("berry_kind").focus()
                return
            }

            const price = Number(document.getElementById("price").value.replace(/,/g, '.'))
            if (!price || isNaN(price)) {
                document.getElementById("price").focus()
                return
            }
            const max_num_pallets = Number(document.getElementById("plan_max_num_pallets").value.replace(/,/g, '.'))
            if (!max_num_pallets || isNaN(max_num_pallets)) {
                document.getElementById("plan_max_num_pallets").focus()
                return
            }

            appendPlan({
                berry_kind: bk,
                price: price.toString(),
                max_num_pallets: max_num_pallets,
                allow_incomplete_pallets: document.getElementById("allow_incomplete_pallets").value === "Ne" ? false : true,
                random_id: Math.floor((Math.random() * 1000000) + 1).toString()
            })
            switchPlanMainInput(ev)
        }

        return (
            <div id="planInput" className="inputForm nodisplay">
                <FormHeader {...props} title="Nova Paleta" linkTo={switchPlanMainInput} />
                <label htmlFor="berry_kind">Sorta:
                <select name="berry_kind" id="berry_kind" onChange={updateSelectedBerryKind} defaultValue="--">
                    <option value="--">--</option>
                    {berryKinds.v.map(bk => {return (
                        <option key={bk.id} id={bk.id} value={bk.id}>{bk.name}</option>
                    )})}
                </select>
                </label>
                <label htmlFor="price">Cena za ovu sortu (rsd):
                    <input id="price" type="number" defaultValue="0" className="textinput" name="price" placeholder="Unesi cenu" />
                </label>
                <label htmlFor="plan_max_num_pallets">Maksimalan broj paleta za ovu sortu:
                    <input id="plan_max_num_pallets" type="number" defaultValue="100" className="textinput" name="plan_max_num_pallets" placeholder="Unesi broj" />
                </label>
                <label htmlFor="allow_incomplete_pallets">Dozvoli nepotpune palete?
                <select name="allow_incomplete_pallets" id="allow_incomplete_pallets" defaultValue="Ne">
                    <option value="Ne">Ne</option>
                    <option value="Da">Da</option>
                </select>
                </label>
                <div className="submitLine">
                <button onClick={add}>Dodaj</button>
                </div>
            </div>
        )
    }

    return (
        <>
        <Nav isMain={false} subtitle="Isporuke" {...props} />
        {(buyers.status === 0 || berryKinds.status === 0 || crateKinds.status === 0 || errorMsg.status === 0) && <div className="loadingOverlay"></div>}
        {newDid && <Navigate to={"/deliveries/"+newDid} />}
        {buyers.status === 2 && <Navigate to="/deliveries" />}
        {buyers.status === 1 && berryKinds.status === 1 && crateKinds.status === 1 && buyers.status === 1 &&
        <>
            <PlanInput appendPlan={addPlan} />
            <div id="mainInput" className="inputForm">
            <FormHeader {...props} title="Nova Isporuka" />
            <form method='POST' onSubmit={onSubmit}>
                <label htmlFor="buyer_id">Kupac:
                <select name="buyer_id" id="buyer_id" >
                    {buyers.v.map((b) => 
                        <option key={b.id} value={b.id}>{b.name}</option>
                    )}
                </select>
                </label>
                <label htmlFor="buyer_id">Metod selekcije paleta:
                <select name="selection_method" id="selection_method" >
                    <option value="Optimalan">Optimalan</option>
                    <option value="Ograničen">Ograničen</option>
                </select>
                </label>
                <label htmlFor="max_num_pallets">Maksimalan ukupan broj paleta:
                    <input id="max_num_pallets" type="number" defaultValue="26" className="textinput" name="max_num_pallets" placeholder="Unesi broj" />
                </label>
                <label htmlFor="description">Napomena:
                    <input id="description" type="text" className="textinput" name="description" />
                </label>

                <br />
                <div style={{marginTop: "20px"}} id="articlesView">
                    <ul className="header">
                        <li style={{cursor: "pointer"}} onClick={switchPlanMainInput}><BsPlusCircle size="20"/> &nbsp; Dodaj Sortu</li>
                    </ul>
                </div>
                <div className="hscrollcontainer">
                <table style={{width:"100%"}} id="inputArticles">
                    <thead>
                        <tr>
                            <td align="center">Sorta</td>
                            <td align="center">Cena<br />( za kg )</td>
                            <td align="center">Limit<br />( br. paleta )</td>
                            <td align="center">Samo pune<br />palete?</td>
                            <td align="center">&nbsp;</td>
                        </tr>
                    </thead>
                    <tbody>
                    {plans.v.map(p => {return (
                        <tr key={p.random_id}>
                            <td align="left">{p.berry_kind.name}</td>
                            <td align="center">{p.price}</td>
                            <td align="center">{p.max_num_pallets}</td>
                            <td align="center">{p.allow_incomplete_pallets ? "Ne" : "Da"}</td>
                            <td align="center" onClick={(ev) => {removePlan(p)}}><BsDashCircle size="16" color="darkred"/></td>
                        </tr>
                    )})}
                    </tbody>
                </table>
                </div>
                {errorMsg.status === 1 && errorMsg.error && <div className="submitLine error">{errorMsg.error}</div>}
                <div className="submitLine">
                <input type="submit" className="button" name="submitForm" value="Dalje" />
                </div>
            </form>
            </div>
        </>
        }
        </>
    )
}

export default DeliveryNew
