import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import Nav from '../../components/Nav'
import FormHeader from '../../components/FormHeader';
import { Navigate } from 'react-router-dom';

const PalletEdit = (props) => {
    let { peid, pid } = useParams()
    const [berryKinds, setBerryKinds] = useState({status:0, v:[], error:null})
    const [crateKinds, setCrateKinds] = useState({status:0, v:[], error:null})
    const [pallet, setPallet] = useState({status:0, v:{}, error:null})
    const [errorMsg, setErrorMsg] = useState({status: 1, error:null})


    useEffect(() => {
        const onAttributesResponse = (data, error) => {
            if (error) {
                setPallet({status:2, v:{}, error:error})
            } else {
                setBerryKinds({status:1, v:data.berry_kinds ? data.berry_kinds : [], error:null})
                setCrateKinds({status:1, v:data.crate_kinds ? data.crate_kinds : [], error:null})
            }
        }
        const onGetResponse = (data, error) => { 
            if (error) {
                setPallet({status:2, v:{}, error:error})
            } else {
                setPallet({status:1, v:data, error:null})
            }
        }
        props.backend.PalletGet(onGetResponse, pid)
        props.backend.GetAttributes(onAttributesResponse, {attributes: ["berry_kinds", "crate_kinds"]})
    }, [props.backend])

    const onSubmit = (ev) => {
        ev.preventDefault()
        let fd = new FormData(ev.target)

        const num_crates = Number(fd.get("num_crates").replace(/,/g, '.'))
        if (!num_crates || isNaN(num_crates)) {
            document.getElementById("num_crates").focus()
            return
        }

        const gross_weight_on_entry = Number(fd.get("gross_weight_on_entry").replace(/,/g, '.'))
        if (!gross_weight_on_entry || isNaN(gross_weight_on_entry)) {
            document.getElementById("gross_weight_on_entry").focus()
            return
        }

        const data = {
            berry_kind: {id: fd.get("berry_kind")},
            crate_kind: {id: fd.get("crate_kind")},
            num_crates: num_crates,
            gross_weight_on_entry: gross_weight_on_entry.toString(),
            is_full: document.getElementById("is_full").value === "Da" ? true : false
        }

        setErrorMsg({status: 0, error:null})
        props.backend.PalletUpdate(onSaveResponse, pid, data)
    }

    const onSaveResponse = (data, error) => {
        if (error) {
            setErrorMsg({status: 1, error:error})
        } else {
            setErrorMsg({status: 1, error:null})
            setPallet({status: 2, v:pallet.v, error:null})
        }
    }

    return (
        <>
        <Nav isMain={false} subtitle="Prijem" {...props} />
        {(pallet.status === 0 || berryKinds.status === 0 || crateKinds.status === 0 || errorMsg.status === 0) && <div className="loadingOverlay"></div>}
        {pallet.status === 2 && <Navigate to={"/entries/"+ peid} />}
        {pallet.status === 1 && berryKinds.status === 1 && crateKinds.status === 1 && 
        <>
            <div id="mainInput" className="inputForm">
            <FormHeader {...props} title="Izmena palete" />
            <form method='POST' onSubmit={onSubmit}>
                <label htmlFor="name">Oznaka:
                <input id="name" type="text" className="textinput" name="name" disabled defaultValue={pallet.v.name} />
                </label>

                <label htmlFor="berry_kind">Sorta:
                <select name="berry_kind" id="berry_kind" defaultValue={pallet.v.berry_kind.id}>
                    {berryKinds.v.map(bk => {return (
                        <option key={bk.id} id={bk.id} value={bk.id}>{bk.name}</option>
                    )})}
                </select>
                </label>

                <label htmlFor="crate_kind">Tip gajbice:
                <select name="crate_kind" id="crate_kind" defaultValue={pallet.v.crate_kind.id}>
                    {crateKinds.v.map(ck => {return (
                        <option key={ck.id} id={ck.id} value={ck.id}>{ck.name}</option>
                    )})}
                </select>
                </label>
                <label htmlFor="num_crates">Novi Broj Gajbica:
                    <input id="num_crates" type="number" className="textinput" name="num_crates" defaultValue={pallet.v.num_crates} placeholder="Unesi broj gajbica" />
                </label>

                <label htmlFor="gross_weight_on_entry">Nova Bruto Težina (kg):
                    <input id="gross_weight_on_entry" type="number" className="textinput" name="gross_weight_on_entry" defaultValue={pallet.v.gross_weight_on_entry} placeholder="Unesi težinu" />
                </label>

                <label htmlFor="is_full">Da li je paleta sada puna?
                <select name="is_full" id="is_full" defaultValue="Ne">
                    <option value="Ne">Ne</option>
                    <option value="Da">Da</option>
                </select>
                </label>

                {errorMsg.status === 1 && errorMsg.error && <div className="submitLine error">{errorMsg.error}</div>}
                <div className="submitLine">
                <input type="submit" className="button" name="submitForm" value="Snimi" />
                </div>
            </form>
            </div>
        </>
        }
        </>
    )
}

export default PalletEdit
