import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import Nav from '../../components/Nav'
import FormHeader from '../../components/FormHeader';
import { Navigate } from 'react-router-dom';

const PalletEntryEdit = (props) => {
    let { peid } = useParams()
    const [palletEntry, setPalletEntry] = useState({status:0, v:{}, error:null})
    const [errorMsg, setErrorMsg] = useState({status: 1, error:null})


    useEffect(() => {
        const onGetResponse = (data, error) => { 
            if (error) {
                setPalletEntry({status:2, v:{}, error:error})
            } else {
                setPalletEntry({status:1, v:data, error:null})
            }
        }
        props.backend.PalletEntryGet(onGetResponse, peid)
    }, [props.backend])

    const onSubmit = (ev) => {
        ev.preventDefault()

        const data = {
            status: document.getElementById("status").value,
        }
        setErrorMsg({status: 0, error:null})
        props.backend.PalletEntryUpdateStatus(onSaveResponse, peid, data)
    }

    const onSaveResponse = (_, error) => {
        if (error) {
            setErrorMsg({status: 1, error:error})
        } else {
            setErrorMsg({status: 1, error:null})
            setPalletEntry({status: 2, v:palletEntry.v, error:null})
        }
    }

    return (
        <>
        <Nav isMain={false} subtitle="Isporuke" {...props} />
        {(palletEntry.status === 0 || errorMsg.status === 0) && <div className="loadingOverlay"></div>}
        {(palletEntry.status === 2) && <Navigate to={"/entries/"+ peid} />}
        {palletEntry.status === 1 && 
        <>
            <div id="mainInput" className="inputForm">
            <FormHeader {...props} title="Izmena statusa prijema" />
            <form method='POST' onSubmit={onSubmit}>
                <label htmlFor="status_id">Novi Status Prijema:
                <select name="status" id="status" defaultValue={palletEntry.v.status}>
                    <option id="U Pripremi" value="U Pripremi">U Pripremi</option>
                    <option id="Primljeno" value="Primljeno">Primljeno</option>
                </select>
                </label>

                {errorMsg.status === 1 && errorMsg.error && <div className="submitLine error">{errorMsg.error}</div>}
                <div className="submitLine">
                <input type="submit" className="button" name="submitForm" value="Snimi" />
                </div>
            </form>
            </div>
        </>
        }
        </>
    )
}

export default PalletEntryEdit
