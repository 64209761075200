import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom';
import DeliveryEdit from './pages/deliveries/DeliveryEdit';
import DeliveryList from './pages/deliveries/DeliveryList';
import DeliveryNew from './pages/deliveries/DeliveryNew';
import DeliveryPalletMeasure from './pages/deliveries/DeliveryPalletMeasure';
import DeliveryView from './pages/deliveries/DeliveryView';
import PalletEdit from './pages/entries/PalletEdit';
import PalletEntryList from './pages/entries/PalletEntryList';
import PalletEntryNew from './pages/entries/PalletEntryNew';
import PalletEntryView from './pages/entries/PalletEntryView';

import MainMenu from './pages/MainMenu'
import StockOverview from './pages/stock/StockCurrent';
import StockTraffic from './pages/stock/StockTraffic';
import PrintEntry from './pages/entries/PrintEntry';
import PrintDelivery from './pages/deliveries/PrintDelivery';
import PrintPalletSticker from './pages/entries/PrintPalletSticker';
import PalletNew from './pages/entries/PalletNew';
import PalletEntryEdit from './pages/entries/PalletEntryEdit';
import StockAudit from './pages/stock/StockAudit';
import StockWaterLoss from './pages/stock/StockWaterLoss';
import AccountSettings from './pages/AccountSettings';
import BuyerList from './pages/buyers/BuyerList';
import BuyerNew from './pages/buyers/BuyerNew';

const Landing = (props) => {
    const myProps = props;

    const userRoutes = () => {
        return (
            <Routes>
            <Route exact path="/pallets/edit/:peid/:pid" element={<PalletEdit {...props} {...myProps}/>} />
            <Route exact path="/entries/new" element={<PalletEntryNew {...props} {...myProps}/>} />
            <Route exact path="/entries/:peid/new" element={<PalletNew {...props} {...myProps}/>} />
            <Route exact path="/entries/:peid/edit" element={<PalletEntryEdit {...props} {...myProps}/>} />
            <Route exact path="/entries/:peid" element={<PalletEntryView {...props} {...myProps}/>} />
            
            <Route exact path="/entries" element={<PalletEntryList {...props} {...myProps}/>} />

            <Route exact path="/deliveries/pallet_measure/:did/:pid" element={<DeliveryPalletMeasure {...props} {...myProps}/>} />
            <Route exact path="/deliveries/new" element={<DeliveryNew {...props} {...myProps}/>} />
            <Route exact path="/deliveries/edit/:did" element={<DeliveryEdit {...props} {...myProps}/>} />
            <Route exact path="/deliveries/:did" element={<DeliveryView {...props} {...myProps}/>} />
            <Route exact path="/deliveries" element={<DeliveryList {...props} {...myProps}/>} />

            <Route exact path="/stock/overview" element={<StockOverview {...props} {...myProps}/>} />
            <Route exact path="/stock/flow" element={<StockTraffic {...props} {...myProps}/>} />
            <Route exact path="/stock/audit" element={<StockAudit {...props} {...myProps}/>} />
            <Route exact path="/stock/waterloss" element={<StockWaterLoss {...props} {...myProps}/>} />
            <Route exact path="/stock" element={<StockOverview {...props} {...myProps}/>} />

            <Route exact path="/buyers/new" element={<BuyerNew {...props} {...myProps}/>} />
            <Route exact path="/buyers" element={<BuyerList {...props} {...myProps}/>} />

            <Route exact path="/print/entry/:peid" element={<PrintEntry {...props} {...myProps}/>} />
            <Route exact path="/print/delivery/:did" element={<PrintDelivery {...props} {...myProps}/>} />
            <Route exact path="/print/pallet/:pid/:pno" element={<PrintPalletSticker {...props} {...myProps}/>} />

            <Route exact path="/accountsettings" element={<AccountSettings {...props} {...myProps}/>} />
            
            <Route path="/home" element={<MainMenu {...props} {...myProps} />} />
            <Route path="/" element={<Navigate to="/home" />} />
            
            </Routes>
        )
    }
    return (
        <>
        {userRoutes()}
        </>
    )    
}
/*
const Landing = ({user, authService, clearUserProfile}) => {
    <Route path="/home" render={(props) => (<MainMenu {...props} {...myProps} />)} />

    return (
        <Switch>
        <Route path="/irrigation/programs"><Irrigation user={user} authService={authService} clearUserProfile={clearUserProfile} section="programs"/></Route>
        <Route path="/irrigation/drain"
        <Route path="/irrigation"><Irrigation user={user} authService={authService} clearUserProfile={clearUserProfile} section="overview"/></Route>
        <Route path="/mixtures"><Mixtures user={user} authService={authService} clearUserProfile={clearUserProfile} /></Route>
        <Route path="/chat"><Chat user={user} authService={authService} clearUserProfile={clearUserProfile} /></Route>
        <Route path="/reports"><Reports user={user} authService={authService} clearUserProfile={clearUserProfile} /></Route>
        <Route path="/inventory"><Inventory user={user} authService={authService} clearUserProfile={clearUserProfile} /></Route>
        <Route path="/config"><FarmConfig user={user} authService={authService} clearUserProfile={clearUserProfile} /></Route>
        <Route path="/accountsettings"><AccountSettings user={user} authService={authService} clearUserProfile={clearUserProfile} /></Route>
        <Route path="/"><MainMenu user={user} authService={authService} clearUserProfile={clearUserProfile} /></Route>
        </Switch>
    )
}
*/

export default Landing
