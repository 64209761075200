import React from 'react'
import MainListItem from '../components/MainListItem'
import { FaChartLine } from 'react-icons/fa'
import InputIcon from '@mui/icons-material/Input';
import OutputIcon from '@mui/icons-material/Output';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import Nav from '../components/Nav'

const MainMenu = (props) => {
    
    const iconInput = <InputIcon sx={{ fontSize: "48px", color:"green" }} />
    const iconOutput = <OutputIcon sx={{ fontSize: "48px", color:"red" }} />
    const iconReports = <FaChartLine size="48px" color="orange"/>
    const iconBuyer = <ShoppingCartIcon sx={{ fontSize: "48px", color:"green" }}/>

    return (
        <>
        <Nav isMain={true} {...props} />
        <div className='mainmenu'>
            <ul>
                <MainListItem user={props.user} allowedRoles={["user"]} caption="Prijem u hladnjaču" icon={iconInput} path="/entries" />
                <MainListItem user={props.user} allowedRoles={["user"]} caption="Izveštaji" icon={iconReports} path="/stock" />
                <MainListItem user={props.user} allowedRoles={["user"]} caption="Isporuke kupcu" icon={iconOutput} path="/deliveries" />
                <MainListItem user={props.user} allowedRoles={["user"]} caption="Kupci" icon={iconBuyer} path="/buyers" />
            </ul>
        </div>
        </>
    )
}

export default MainMenu