export default class Backend {
    constructor() {
        //this.url = "http://192.168.1.136:9888"
        this.url = "https://hladnjaca.uritagro.com"
        //this.url = "http://192.168.1.104:9888"
        // duca apartman
        //this.url = "http://192.168.0.31:9888"
        // android
        //this.url = "http://192.168.195.112:9888"
        this.mode = 'cors' // no-cors, *cors, same-origin
        this.credentials = 'include' // include, *same-origin, omit
        this.cookie = "empty"
        this.headers = new Headers()
        this.headers.append('Content-Type', 'application/json')
    }

    ReportsIrrigation(responseConsumer, year, aggregate, valveIndex) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
            headers: this.headers,
        };
        const endpoint = this.url + "/api/reports/irrigation?year="+year+"&aggregate="+aggregate+"&valve=" + valveIndex;
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    SwitchFarm(responseConsumer, farmID) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
            headers: this.headers,
        };
        const endpoint = this.url + "/api/switch_farm/" + farmID;
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    BuyerNew(responseConsumer, data) {
        const requestOptions = {
            method: "POST",
            credentials: this.credentials,
            body: JSON.stringify(data),
        }
        const endpoint = this.url + "/api/buyers";
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    BuyerList(responseConsumer) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
        }
        const endpoint = this.url + "/api/buyers";
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    WaterLossList(responseConsumer) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
        }
        const endpoint = this.url + "/api/waterloss";
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    AuditList(responseConsumer) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
        }
        const endpoint = this.url + "/api/pallets/history";
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    ShareListDaily(responseConsumer, data) {
        const requestOptions = {
            method: "POST",
            credentials: this.credentials,
            body: JSON.stringify(data),
        }
        const endpoint = this.url + "/api/shares";
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    StockGetCurrent(responseConsumer, data) {
        const requestOptions = {
            method: "POST",
            credentials: this.credentials,
            body: JSON.stringify(data),
        }
        const endpoint = this.url + "/api/stock";
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    EntryDocUpload(responseConsumer, data, id) {
        const requestOptions = {
            method: "POST",
            credentials: this.credentials,
            body: data,
        }
        const endpoint = this.url + "/api/documents/entries/" + id;
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    DeliveryDocUpload(responseConsumer, data, id) {
        const requestOptions = {
            method: "POST",
            credentials: this.credentials,
            body: data,
        }
        const endpoint = this.url + "/api/documents/deliveries/" + id;
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    GeneratePalletName(responseConsumer, farm_id) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
            headers: this.headers,
        };
        const endpoint = this.url + "/api/pallets/generate_name/" + farm_id;
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    DeliveryUpdateStatus(responseConsumer, did, data) {
        const requestOptions = {
            method: "PUT",
            credentials: this.credentials,
            headers: this.headers,
            body: JSON.stringify(data)
        };
        const endpoint = this.url + "/api/deliveries/status/" + did;
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    DeliveryPalletMeasureUpdate(responseConsumer, did, pid, data) {
        const requestOptions = {
            method: "PUT",
            credentials: this.credentials,
            headers: this.headers,
            body: JSON.stringify(data)
        };
        const endpoint = this.url + "/api/deliveries/measure/" + did + "/" + pid;
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    DeliveryReserve(responseConsumer, did) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
            headers: this.headers,
        };
        const endpoint = this.url + "/api/deliveries/reserve/" + did;
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    DeliveryGet(responseConsumer, did) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
            headers: this.headers,
        };
        const endpoint = this.url + "/api/deliveries/" + did;
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    DeliveryList(responseConsumer) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
            headers: this.headers,
        };
        const endpoint = this.url + "/api/deliveries";
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }
    DeliveryNew(responseConsumer, data) {
        const requestOptions = {
            method: "POST",
            credentials: this.credentials,
            headers: this.headers,
            body: JSON.stringify(data)
        };
        const endpoint = this.url + "/api/deliveries";
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    PalletNew(responseConsumer, peid, data) {
        const requestOptions = {
            method: "POST",
            credentials: this.credentials,
            headers: this.headers,
            body: JSON.stringify(data)
        };
        const endpoint = this.url + "/api/pallet_entries/" + peid;
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    PalletDelete(responseConsumer, peid, pid) {
        const requestOptions = {
            method: "POST",
            credentials: this.credentials,
            headers: this.headers,
        };
        const endpoint = this.url + "/api/pallet_entries/" + peid + "/" + pid + "/delete";
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    PalletGet(responseConsumer, pid) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
            headers: this.headers,
        };
        const endpoint = this.url + "/api/pallets/" + pid;
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    PalletUpdate(responseConsumer, pid, data) {
        const requestOptions = {
            method: "PUT",
            credentials: this.credentials,
            headers: this.headers,
            body: JSON.stringify(data)
        };
        const endpoint = this.url + "/api/pallets/" + pid;
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    PalletEntryList(responseConsumer) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
            headers: this.headers,
        };
        const endpoint = this.url + "/api/pallet_entries";
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    PalletEntryGet(responseConsumer, peid) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
            headers: this.headers,
        };
        const endpoint = this.url + "/api/pallet_entries/" + peid;
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    PalletEntryUpdateStatus(responseConsumer, peid, data) {
        const requestOptions = {
            method: "PUT",
            credentials: this.credentials,
            headers: this.headers,
            body: JSON.stringify(data)
        };
        const endpoint = this.url + "/api/pallet_entries/" + peid;
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    PalletEntryNew(responseConsumer, data) {
        const requestOptions = {
            method: "POST",
            credentials: this.credentials,
            headers: this.headers,
            body: JSON.stringify(data)
        };
        const endpoint = this.url + "/api/pallet_entries";
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    TankGet(responseConsumer, id) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
            headers: this.headers,
        };
        const endpoint = this.url + "/api/farms/tanks/" + id;
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    TankList(responseConsumer) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
            headers: this.headers,
        };
        const endpoint = this.url + "/api/farms/tanks";
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    TankUpdate(responseConsumer, id, data) {
        const requestOptions = {
            method: "PUT",
            credentials: this.credentials,
            headers: this.headers,
            body: JSON.stringify(data)
        };
        const endpoint = this.url + "/api/farms/tanks/" + id;
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    TankNew(responseConsumer, data) {
        const requestOptions = {
            method: "POST",
            credentials: this.credentials,
            headers: this.headers,
            body: JSON.stringify(data)
        };
        const endpoint = this.url + "/api/farms/tanks";
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    LayoutRowList(responseConsumer) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
            headers: this.headers,
        };
        const endpoint = this.url + "/api/layout_rows";
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    LayoutRowGet(responseConsumer, id) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
            headers: this.headers
        };
        const endpoint = this.url + "/api/layout_rows/" + id;
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    LayoutRowUpdate(responseConsumer, id, data) {
        const requestOptions = {
            method: "PUT",
            credentials: this.credentials,
            headers: this.headers,
            body: JSON.stringify(data)
        };
        const endpoint = this.url + "/api/layout_rows/" + id;
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    LayoutRowNew(responseConsumer, data) {
        const requestOptions = {
            method: "POST",
            credentials: this.credentials,
            headers: this.headers,
            body: JSON.stringify(data)
        };
        const endpoint = this.url + "/api/layout_rows";
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    NetafimCycleList(responseConsumer, from, to) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
            headers: this.headers,
        };
        const endpoint = this.url + "/api/netafimcloud/valvecycles?from="+from+"&to="+to;
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    MaintenanceNew(responseConsumer, data) {
        const requestOptions = {
            method: "POST",
            credentials: this.credentials,
            headers: this.headers,
            body: JSON.stringify(data)
        };
        const endpoint = this.url + "/api/maintenance";
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    MaintenanceList(responseConsumer) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
            headers: this.headers,
        };
        const endpoint = this.url + "/api/maintenance"
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    FieldWorkNew(responseConsumer, data) {
        const requestOptions = {
            method: "POST",
            credentials: this.credentials,
            headers: this.headers,
            body: JSON.stringify(data)
        };
        const endpoint = this.url + "/api/field_works";
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    FieldWorkList(responseConsumer) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
            headers: this.headers,
        };
        const endpoint = this.url + "/api/field_works"
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    LawnCutNew(responseConsumer, data) {
        const requestOptions = {
            method: "POST",
            credentials: this.credentials,
            headers: this.headers,
            body: JSON.stringify(data)
        };
        const endpoint = this.url + "/api/lawn_cuts";
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    LawnCutList(responseConsumer) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
            headers: this.headers,
        };
        const endpoint = this.url + "/api/lawn_cuts"
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    SprayingNew(responseConsumer, data) {
        const requestOptions = {
            method: "POST",
            credentials: this.credentials,
            headers: this.headers,
            body: JSON.stringify(data)
        };
        const endpoint = this.url + "/api/sprayings";
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    SprayingList(responseConsumer) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
            headers: this.headers,
        };
        const endpoint = this.url + "/api/sprayings"
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    TankRefillNew(responseConsumer, data) {
        const requestOptions = {
            method: "POST",
            credentials: this.credentials,
            headers: this.headers,
            body: JSON.stringify(data)
        };
        const endpoint = this.url + "/api/tank_refills";
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    TankRefillList(responseConsumer) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
            headers: this.headers,
        };
        const endpoint = this.url + "/api/tank_refills"
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    TracktorRefillNew(responseConsumer, data) {
        const requestOptions = {
            method: "POST",
            credentials: this.credentials,
            headers: this.headers,
            body: JSON.stringify(data)
        };
        const endpoint = this.url + "/api/tracktor_refills";
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    TracktorRefillList(responseConsumer) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
            headers: this.headers,
        };
        const endpoint = this.url + "/api/tracktor_refills"
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    DocumentGet(responseConsumer, docID) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
            headers: this.headers,
        };
        const endpoint = this.url + "/api/documents/" + docID
        this.doBlobFetch(endpoint, requestOptions, responseConsumer)
    }
    DocumentUpload(responseConsumer, data) {
        const requestOptions = {
            method: "POST",
            credentials: this.credentials,
            body: data,
        }
        const endpoint = this.url + "/api/documents/new"
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    SupplierList(responseConsumer) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
            headers: this.headers,
        };
        const endpoint = this.url + "/api/suppliers"
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }
    SupplierNew(responseConsumer, data) {
        const requestOptions = {
            method: "POST",
            credentials: this.credentials,
            headers: this.headers,
            body: JSON.stringify(data)
        };
        const endpoint = this.url + "/api/suppliers";
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }
    SupplierUpdate(responseConsumer, id, data) {
        const requestOptions = {
            method: "PUT",
            credentials: this.credentials,
            headers: this.headers,
            body: JSON.stringify(data)
        };
        const endpoint = this.url + "/api/suppliers/" + id;
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }
    SupplierGet(responseConsumer, id) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
            headers: this.headers,
        };
        const endpoint = this.url + "/api/suppliers/"+id;
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    InventoryTotalByYear(responseConsumer) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
            headers: this.headers,
        };
        const endpoint = this.url + "/api/inventory/total_by_year"
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    InventoryList(responseConsumer) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
            headers: this.headers,
        };
        const endpoint = this.url + "/api/inventory"
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    InventoryProductLogs(responseConsumer, pid) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
            headers: this.headers,
        };
        const endpoint = this.url + "/api/inventory/logs/"+pid
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    ProductList(responseConsumer) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
            headers: this.headers,
        };
        const endpoint = this.url + "/api/products"
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    ProductNew(responseConsumer, data) {
        const requestOptions = {
            method: "POST",
            credentials: this.credentials,
            headers: this.headers,
            body: JSON.stringify(data)
        };
        const endpoint = this.url + "/api/products";
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }
    ProductUpdate(responseConsumer, id, data) {
        const requestOptions = {
            method: "PUT",
            credentials: this.credentials,
            headers: this.headers,
            body: JSON.stringify(data)
        };
        const endpoint = this.url + "/api/products/" + id;
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }
    ProductGet(responseConsumer, id) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
            headers: this.headers,
        };
        const endpoint = this.url + "/api/products/"+id;
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    FormulaList(responseConsumer, filter) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
            headers: this.headers,
        };
        const endpoint = this.url + "/api/formulas?filter=" + filter
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }
    FormulaNew(responseConsumer, data) {
        const requestOptions = {
            method: "POST",
            credentials: this.credentials,
            headers: this.headers,
            body: JSON.stringify(data)
        };
        const endpoint = this.url + "/api/formulas";
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }
    FormulaUpdate(responseConsumer, id, data) {
        const requestOptions = {
            method: "PUT",
            credentials: this.credentials,
            headers: this.headers,
            body: JSON.stringify(data)
        };
        const endpoint = this.url + "/api/formulas/" + id;
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }
    FormulaGet(responseConsumer, id) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
            headers: this.headers,
        };
        const endpoint = this.url + "/api/formulas/"+id;
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    ValveCycleList(responseConsumer, from, to) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
            headers: this.headers,
        };
        const endpoint = this.url + "/api/valvecycle?from="+from+"&to="+to;
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    ValveCycleNew(responseConsumer, data) {
        const requestOptions = {
            method: "POST",
            credentials: this.credentials,
            headers: this.headers,
            body: JSON.stringify(data)
        };
        const endpoint = this.url + "/api/valvecycle";
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    IrrigationProgramList(responseConsumer) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
            headers: this.headers,
        };
        const endpoint = this.url + "/api/programs/irrigation";
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }
    IrrigationProgramUpdate(responseConsumer, id, data) {
        const requestOptions = {
            method: "PUT",
            credentials: this.credentials,
            headers: this.headers,
            body: JSON.stringify(data)
        };
        const endpoint = this.url + "/api/programs/irrigation/" + id;
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    IrrigationProgramGet(responseConsumer, id) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
            headers: this.headers,
        };
        const endpoint = this.url + "/api/programs/irrigation/"+id;
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    IrrigationProgramNew(responseConsumer, data) {
        const requestOptions = {
            method: "POST",
            credentials: this.credentials,
            headers: this.headers,
            body: JSON.stringify(data)
        };
        const endpoint = this.url + "/api/programs/irrigation";
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    DosingProgramList(responseConsumer) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
            headers: this.headers,
        };
        const endpoint = this.url + "/api/programs/dosing";
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    DosingProgramNew(responseConsumer, data) {
        const requestOptions = {
            method: "POST",
            credentials: this.credentials,
            headers: this.headers,
            body: JSON.stringify(data)
        };
        const endpoint = this.url + "/api/programs/dosing";
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    DosingProgramUpdate(responseConsumer, id, data) {
        const requestOptions = {
            method: "PUT",
            credentials: this.credentials,
            headers: this.headers,
            body: JSON.stringify(data)
        };
        const endpoint = this.url + "/api/programs/dosing/" + id;
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    DosingProgramGet(responseConsumer, id) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
            headers: this.headers,
        };
        const endpoint = this.url + "/api/programs/dosing/"+id;
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    RuntimeProgramList(responseConsumer) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
            headers: this.headers,
        };
        const endpoint = this.url + "/api/programs/runtime";
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    RuntimeProgramNew(responseConsumer, data) {
        const requestOptions = {
            method: "POST",
            credentials: this.credentials,
            headers: this.headers,
            body: JSON.stringify(data)
        };
        const endpoint = this.url + "/api/programs/runtime";
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    RuntimeProgramUpdate(responseConsumer, id, data) {
        const requestOptions = {
            method: "PUT",
            credentials: this.credentials,
            headers: this.headers,
            body: JSON.stringify(data)
        };
        const endpoint = this.url + "/api/programs/runtime/" + id;
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    RuntimeProgramGet(responseConsumer, id) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
            headers: this.headers,
        };
        const endpoint = this.url + "/api/programs/runtime/"+id;
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    DrainMeasurementsList(responseConsumer) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
            headers: this.headers,
        };
        const endpoint = this.url + "/api/measurements";
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    DrainMeasurementsNew(responseConsumer, data) {
        const requestOptions = {
            method: "POST",
            credentials: this.credentials,
            headers: this.headers,
            body: JSON.stringify(data),
        };
        const endpoint = this.url + "/api/measurements";
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }
    FarmDrainsUpdate(responseConsumer, data) {
        const requestOptions = {
            method: "PUT",
            credentials: this.credentials,
            headers: this.headers,
            body: JSON.stringify(data),
        };
        const endpoint = this.url + "/api/farms/drains/" + data.id;
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    FarmDrainsGet(responseConsumer, drainID) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
            headers: this.headers,
        };
        const endpoint = this.url + "/api/farms/drains/" + drainID;
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    FarmDrainsNew(responseConsumer, data) {
        const requestOptions = {
            method: "POST",
            credentials: this.credentials,
            headers: this.headers,
            body: JSON.stringify(data)
        };
        const endpoint = this.url + "/api/farms/drains";
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    FarmDrainsList(responseConsumer) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
            headers: this.headers,
        };
        const endpoint = this.url + "/api/farms/drains";
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    FarmUserGet(responseConsumer, uid) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
            headers: this.headers,
        };
        const endpoint = this.url + "/api/farms/users/" + uid;
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    FarmUsersList(responseConsumer) {
        const requestOptions = {
            method: "GET",
            credentials: this.credentials,
            headers: this.headers,
        };
        const endpoint = this.url + "/api/farms/users";
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    FarmUsersNew(responseConsumer, data) {
        const requestOptions = {
            method: "POST",
            credentials: this.credentials,
            headers: this.headers,
            body: JSON.stringify(data)
        }
        const endpoint = this.url + "/api/farms/users";
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    IrrigationSystemUpdate(responseConsumer, inputData) {
        const requestOptions = {
            method: "PUT",
            mode: this.mode,
            cache: 'no-cache',
            credentials: this.credentials,
            body: JSON.stringify(inputData)
        }
        const endpoint = this.url + "/api/systems/"+inputData.id;
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    IrrigationSystemRemove(responseConsumer, id) {
        const requestOptions = {
            method: "DELETE",
            mode: this.mode,
            cache: 'no-cache',
            credentials: this.credentials,
            headers: this.headers,
            body: JSON.stringify({})
        };
        const endpoint = this.url + "/api/systems/"+id;
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }
    IrrigationSystemSave(responseConsumer, inputData) {
        const requestOptions = {
            method: "POST",
            mode: this.mode,
            cache: 'no-cache',
            credentials: this.credentials,
            headers: this.headers,
            body: JSON.stringify(inputData)
        };
        const endpoint = this.url + "/api/systems";
        this.doFetch(endpoint, requestOptions, responseConsumer)  
    }

    IrrigationSystemList(responseConsumer) {
        const requestOptions = {
            method: "GET",
            mode: this.mode,
            cache: 'no-cache',
            credentials: this.credentials,
            headers: this.headers,
        };
        const endpoint = this.url + "/api/systems";
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    GetAttributes(responseConsumer, data) {
        const requestOptions = {
            method: "POST",
            mode: this.mode,
            cache: 'no-cache',
            credentials: this.credentials,
            headers: this.headers,
            body: JSON.stringify(data),
        };
        const endpoint = this.url + "/api/attributes";
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    FarmGet(responseConsumer) {
        const requestOptions = {
            method: "GET",
            mode: this.mode,
            cache: 'no-cache',
            credentials: this.credentials,
            headers: this.headers
        };
        const endpoint = this.url + "/api/farms";
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    FarmSave(responseConsumer, farm) {
        const requestOptions = {
            method: "PUT",
            mode: this.mode,
            cache: 'no-cache',
            credentials: this.credentials,
            body: JSON.stringify(farm)
        };
        const endpoint = this.url + "/api/farms";
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    AdminFarmSave(responseConsumer, item) {
        const requestOptions = {
            method: "PUT",
            mode: this.mode,
            cache: 'no-cache',
            credentials: this.credentials,
            body: JSON.stringify(item)
        };
        const endpoint = this.url + "/api/admin/farms/" + item.farm.id;
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    AdminFarmNew(responseConsumer, item) {
        const requestOptions = {
            method: "POST",
            mode: this.mode,
            cache: 'no-cache',
            credentials: this.credentials,
            body: JSON.stringify(item)
        }
        const endpoint = this.url + "/api/admin/farms"
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    AdminFarmList(responseConsumer) {
        const requestOptions = {
            method: "GET",
            mode: this.mode,
            cache: 'no-cache',
            credentials: this.credentials,
        };
        const endpoint = this.url + "/api/admin/farms";
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    UserInvitationGet(responseConsumer, code) {
        const requestOptions = {
            method: "GET",
            mode: this.mode,
            cache: 'no-cache',
            credentials: this.credentials,
        };
        const endpoint = this.url + "/api/users/i/" + code;
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    UserInvitationSubmit(responseConsumer, user) {
        const requestOptions = {
            method: "POST",
            mode: this.mode,
            cache: "no-cache",
            credentials: this.credentials,
            body: JSON.stringify(user)
        }
        const endpoint = this.url + "/api/users/i/" + user.invitation_code
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    UserUpdate(responseConsumer, user) {
        const requestOptions = {
            method: "PUT",
            mode: this.mode,
            cache: "no-cache",
            credentials: this.credentials,
            body: JSON.stringify(user)
        }
        const endpoint = this.url + "/api/users"
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    GetUserProfile(responseConsumer) {
        const requestOptions = {
            method: 'GET',
            mode: this.mode,
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: this.credentials,
            headers: this.headers,
            // body: JSON.stringify({ title: 'React POST Request Example' })
        };
        let endpoint = this.url + "/api/users/me"
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    UserPasswordResetGet(responseConsumer, code) {
        const requestOptions = {
            method: "GET",
            mode: this.mode,
            cache: 'no-cache',
            credentials: this.credentials,
        };
        const endpoint = this.url + "/api/users/r/" + code;
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }
    UserPasswordResetSubmit(responseConsumer, data) {
        const requestOptions = {
            method: "POST",
            mode: this.mode,
            cache: 'no-cache',
            credentials: this.credentials,
            body: JSON.stringify(data)
        };
        const endpoint = this.url + "/api/users/r/" + data.reset_code;
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    ResetPassword(responseConsumer, email) {
        const requestOptions = {
            method: 'POST',
            mode: this.mode,
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: this.credentials,
            headers: this.headers,
            body: JSON.stringify({ email: email })
        };
        let endpoint = this.url + "/api/users/reset_password"
        this.doFetch(endpoint, requestOptions, responseConsumer)
    }

    Authenticate(user, pass, responseConsumer) {
        const requestOptions = {
            method: 'POST',
            mode: this.mode,
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: this.credentials,
            headers: this.headers,
            body: JSON.stringify({email: user, Password: pass}),
        }
        let endpoint = this.url + "/api/users/auth"
        fetch(endpoint, requestOptions)
        .then(resp => {
            if (resp.ok || resp.status === 200) {
                return Promise.resolve("ok")
            }
            return Promise.resolve("Invalid email or password")
        })
        .then(resp => responseConsumer(resp === "ok" ? null : resp))
        .catch(_ => responseConsumer("communication error")
        )
    }

    Logout(onComplete) {
        const requestOptions = {
            method: 'GET',
            mode: this.mode,
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: this.credentials,
            headers: this.headers
        }
        let endpoint = this.url + "/api/users/logout"
        fetch(endpoint, requestOptions)
        .then(_ => window.location = '/')
        .catch(e => {})
    }

    doBlobFetch(endpoint, requestOptions, responseConsumer) {
        fetch(endpoint, requestOptions)
        .then(resp => {
            if (resp.status === 401 && window.location.pathname !== "/" && !window.location.pathname.startsWith("/users/i/") && !window.location.pathname.startsWith("/users/r/") ) {
                window.location = "/"
            }
            return resp;
        })
        .then(resp => {
            const ct = resp.headers.get("Content-Type")
            if (ct === "application/json") {
                resp.json().then(data => responseConsumer(ct, null, data.error))
            } else {
                resp.blob().then(data => responseConsumer(ct, data, null))
            }
        })
        .catch(e => {
            responseConsumer("", null, "communication error")
        })
    }
    doFetch(endpoint, requestOptions, responseConsumer) {
        fetch(endpoint, requestOptions)
        .then(resp => {
            if (resp.status === 401 && window.location.pathname !== "/" 
                && !window.location.pathname.startsWith("/users/i/") 
                && !window.location.pathname.startsWith("/users/r/")) {

                window.location = "/"
            }
            return resp;
        })
        .then(resp => resp.json())
        .then(data => {
            if (data.error !== undefined) {
                responseConsumer(null, data.error)
            } else {
                responseConsumer(data, null)
            }
        })
        .catch(e => {
            responseConsumer(null, "communication error")
        })
    }
}


