import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";

function PrintEntry(props) {
    let { peid } = useParams()
    const [palletEntry, setPalletEntry] = useState({status: 0, v:{}, error:null})
    const [summary, setSummary] = useState({status: 0, v:{}})

    const dateHead = (date) => {
        var d = new Date(date)
        var options = { day: '2-digit', month: '2-digit', year: 'numeric'};
        return d.toLocaleDateString('sr-latn', options)
    }

    useEffect(() => {
        const onGetResponse = (data, error) => {
            if (error) {
                setPalletEntry({status: 1, v:{}, error: error})
            } else {
                setPalletEntry({status: 1, v:data, error: null})
                setSummary({status: 1, v:computeSummary(data)})
            }
        }
        props.backend.PalletEntryGet(onGetResponse, peid)
    }, [props.backend])

    const computeSummary = (pe) => {
        var total_pallet_weight = 0.0
        var total_crate_weight = 0.0
        var total_gross_weight = 0.0
        var total_net_weight = 0.0

        pe.pallets.forEach(p => {
            total_pallet_weight += parseFloat(p.pallet_weight)
            total_crate_weight += p.crate_kind.weight * p.num_crates
            total_gross_weight += parseFloat(p.gross_weight_on_entry)
            total_net_weight += parseFloat(p.net_weight_on_entry)
        })
        
        return {
            total_pallet_weight: Number(total_pallet_weight),
            total_crate_weight: Number(total_crate_weight),
            total_gross_weight: Number(total_gross_weight),
            total_net_weight: Number(total_net_weight)
        }
    }
    return (
        <>
        {(palletEntry.status === 0 || summary.status === 0) && <div className="loadingOverlay"></div>}
        {palletEntry.status === 1 && summary.status === 1 &&
        <div className="content print">
            <div className="printheader">
                <div>
                    <b>Voćarska zadruga De La Tero</b><br />
                    Dušana Lončara BB<br />
                    15354 Štitar, Šabac<br />
                </div>
                <div>
                    Proizvodna lokacija: <b>{palletEntry.v.farm.name}</b><br />
                    Datum predaje robe: {dateHead(palletEntry.v.created_at)}
                </div>
            </div>
            
            <p className="contentSubtitle">Specifikacija prijema</p><br />
            <div>
            <table className='simpletable'>
                <thead>
                <tr>
                    <th align="center" >Datum<br />berbe</th>
                    <th align="center" >Oznaka<br />palete</th>
                    <th align="center" >Sorta</th>
                    <th align="center" >Težina<br />palete</th>
                    <th align="center" >Tip<br />Gajbica</th>
                    <th align="center" >Broj<br />Gajbica</th>
                    <th align="center" >Bruto<br />( kg )</th>
                    <th align="center" >Neto<br />( kg )</th>
                </tr>
                </thead>
                <tbody>
                {palletEntry.v.pallets.length === 0 &&
                    <tr><td colSpan="8">Nema unetih paleta</td></tr>
                }
                {palletEntry.v.pallets.length > 0 && palletEntry.v.pallets.map(p => { 
                    return (
                        <tr key={p.id}>
                        <td align="center">{dateHead(p.harvest_date)}</td>
                        <td align="center">{p.name}</td>
                        <td align="center">{p.berry_kind.name}</td>
                        <td align="center">{p.pallet_weight}</td>
                        <td align="center">{p.crate_kind.name}</td>
                        <td align="center">{p.num_crates}</td>
                        <td align="center">{Number(p.gross_weight_on_entry).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                        <td align="center">{Number(p.net_weight_on_entry).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
            </div><br />
            <p className="contentSubtitle">Ukupno</p><br />
            <div>
            <table className='simpletable'>
                <thead>
                <tr>
                    <th align="center" >Broj<br />paleta</th>
                    <th align="center" >Težina paleta<br />( kg )</th>
                    <th align="center" >Težina gajbica<br />( kg )</th>
                    <th align="center" >Bruto<br />( kg )</th>
                    <th align="center" >Neto<br />( kg )</th>
                </tr>
                </thead>
                <tbody>
                    <tr>
                    <td align="center">{palletEntry.v.pallets.length}</td>
                    <td align="center">{summary.v.total_pallet_weight.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                    <td align="center">{summary.v.total_crate_weight.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                    <td align="center">{summary.v.total_gross_weight.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                    <td align="center">{summary.v.total_net_weight.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                    </tr>
                </tbody>
            </table>
            </div>
            <br /><br />
            <div className="printheader">
                <div>
                    Robu primio<br /><br /><br />
                    _______________________________
                </div>
                <div>
                    Robu predao<br /><br /><br />
                    _______________________________
                </div>
            </div>
        </div>
        }
        </>
    )
}

export default PrintEntry
