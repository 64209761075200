import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { BsPencil } from 'react-icons/bs'

import Nav from '../../components/Nav'
import ActionButton from '../../components/ActionButton';

function BuyerList(props) {
    const pencilIcon = <BsPencil size="14"/>
    const [buyers, setBuyers] = useState({status: 0, v:[], error:null})

    useEffect(() => {
        const onListResponse = (data, error) => {
            if (error) {
                setBuyers({status: 1, v:[], error: error})
            } else {
                setBuyers({status: 1, v:data.buyers ? data.buyers : [], error: null})
            }
        }
        props.backend.BuyerList(onListResponse)
    }, [props.backend])
    
    return (
        <>
        <Nav isMain={false} subtitle="Kupci" {...props} />
        {buyers.status === 0 && <div className="loadingOverlay"></div>}
        {buyers.status === 1 && 
        <div className="content">
            {props.user.system_role === "admin" &&
            <div className="filterMenu">
            <Link to="/buyers/new">
                <ActionButton title="Kreiraj" icon={pencilIcon} />
            </Link>
            </div>
            }
            <div className="hscrollcontainer width300">
            <table>
                <thead>
                <tr>
                    <th align="center" >Naziv</th>
                    <th align="center" >Oznaka</th>
                </tr>
                </thead>
                <tbody>
                {buyers.v.length === 0 &&
                    <tr><td colSpan="2">Nema unetih kupaca</td></tr>
                }
                {buyers.v.length > 0 && buyers.v.map(b => { 
                    return (
                        <tr key={b.id}>
                        <td align="center">{b.name}</td>
                        <td align="center">{b.code}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
            </div>    
        </div>
        }
        </>
    )
}

export default BuyerList
