import React from 'react'

const LogoBox = () => {
    return (
        <div className="logobox">
            <div className="company">
                <h4>Hladnjača</h4>
            </div>
            <div className="company">
                <h6 align="right">by URIT</h6>
            </div>
        </div>
    )
}

export default LogoBox
