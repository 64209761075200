import React, {useState} from 'react'
import Nav from '../components/Nav'
import FormHeader from '../components/FormHeader'

const AccountSettings = (props) => {
    const [user, setUser] = useState({
        status: 1,
        error: null
    })

    const onResponse = (data, error) => {
        console.log(data)
        if (error) {
            setUser({status: 1, error: error})
        } else {
            setUser({status: 1, error: null})
            props.reloadProfile()
        }
    }

    const onSubmit = (ev) => {
        ev.preventDefault()
        const fd = new FormData(ev.target)
        const p1 = fd.get("password")
        const p2 = fd.get("password2")
        if (p1 !== p2) {
            document.getElementById("errorNotEqual").classList.remove("nodisplay")
            return
        }
        setUser({status: 0, error:null})
        props.backend.UserUpdate(onResponse, {password: p1})
    }

    const onInputChange = (ev) => {
        document.getElementById("errorNotEqual").classList.add("nodisplay")
        const btn = document.getElementById("submitButton")
        btn.textContent = "Snimi"
        btn.disabled = false
    }

    return (
        <>
        <Nav isMain={false} subtitle="Moj Nalog" {...props} />
        <div className="inputForm">
            <FormHeader {...props} title="Podešavanje naloga" />
            <form method='POST' onSubmit={onSubmit}>
                <label htmlFor="email">
                <input type="text" className="textinput" name="email" value={props.user.email} readOnly/>
                </label>
                <label htmlFor="password">Nova lozinka:
                <input type="password" className="textinput" name="password" placeholder="Unesi novu lozinku" onChange={onInputChange}/>
                </label>
                <label htmlFor="password2">Nova lozinka (ponovo):
                <input type="password" className="textinput" name="password2" placeholder="Unesi novu lozinku" onChange={onInputChange} />
                </label>
                <div id="errorNotEqual" className="error nodisplay">Unete lozinke moraju biti iste</div>
                <br />
                <div className="submitLine">
                <button id="submitButton" 
                        type="submit" 
                        className="button" 
                        disabled={user.status === 1} 
                        name="submitButton" >Snimi</button>
                </div>
                
            </form>
        </div>
        </>
    )
}

export default AccountSettings
