import React from 'react'
import BackButton from './BackButton';

const FormHeader = (props) => {
    return (
        <ul className="header">
            <li>
                {!props.withoutBackButton ? <BackButton {...props} /> : ""}
            </li>
            <li>
                {props.title}
            </li>
        </ul>
    )
}

export default FormHeader