import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";

function PrintPalletSticker(props) {
    let { pid, pno } = useParams()
    const [pallet, setPallet] = useState({status: 0, v:{}, error:null})

    useEffect(() => {
        const onGetResponse = (data, error) => {
            if (error) {
                setPallet({status: 1, v:{}, error: error})
            } else {
                setPallet({status: 1, v:data, error: null})
            }
        }
        props.backend.PalletGet(onGetResponse, pid)
    }, [props.backend])

    return (
        <>
        {(pallet.status === 0) && <div className="loadingOverlay"></div>}
        {pallet.status === 1 &&
        <div className="content palletSticker">
            <div className="printheader">
                <div>&nbsp;</div>
                <div>
                    <b>Pallet no. {(pallet.v.status.name === "ISPORUČENO" && pno !== "0") ? pno : ""}</b><br />
                </div>
            </div>
            
            <p className="contentSubtitle"><b>FRESH BLUEBERRIES</b></p><br />
            <p className="contentSubtitle"><b>1 x {pallet.v.crate_kind.sticker_label}kg</b></p><br /><br />
            <p className='contentSubtitle'>LOT-NR: </p>
            <p className='contentSubtitle'>PALLET CODE: {pallet.v.name}</p><br /><br /><br /><br /><br />

            <div className="printheader">
                <div>Boxes no. {pallet.v.num_crates}</div>
                <div>Packaging: {pallet.v.crate_kind.sticker_label} kg</div>
            </div>
            <br /><br />
            <div className="printheader">
                <div>Gross weight: {pallet.v.status.name === "ISPORUČENO" && pno !== "0" ? 
                    Number(pallet.v.gross_weight_on_exit).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) : 
                    Number(pallet.v.gross_weight_on_entry).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})} kg
                </div>
                <div>Net weight: {pallet.v.status.name === "ISPORUČENO" && pno !== "0" ?
                    Number(pallet.v.net_weight_on_exit).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) : 
                    Number(pallet.v.net_weight_on_entry).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})} kg
                </div>
            </div>
        </div>
        }
        </>
    )
}

export default PrintPalletSticker
