import React from 'react'
import TopMenu from './TopMenu'

const StockMenu = (props) => {
    const menuItems = [
        {
            key: "overview",
            path: "/stock/overview",
            title: "Stanje",
            selected: props.section === "overview",
        }
    ]

    menuItems.push(
        {
            key: "flow",
            path: "/stock/flow",
            title: "Ulaz/Izlaz",
            selected: props.section === "flow",
        },
        {
            key: "audit",
            path: "/stock/audit",
            title: "Revizije",
            selected: props.section === "audit",
        },
        {
            key: "waterloss",
            path: "/stock/waterloss",
            title: "Kalo",
            selected: props.section === "waterloss",
        }
    )

    return (
        <TopMenu {...props} menuItems={menuItems} />
    )
}

export default StockMenu
