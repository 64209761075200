import React, { useEffect, useState } from 'react'
import Select from 'react-select'

import Nav from '../../components/Nav'
import StockMenu from '../../components/StockMenu'

function StockTraffic(props) {
    const [errorMsg, setErrorMsg] = useState({status: 1, error:null})
    const [berryKinds, setBerryKinds] = useState({status: 0, v:[], error:null})
    const [berryKindOpts, setBerryKindOpts] = useState({status:0, v:[]})
    const [reports, setReports] = useState({status: 1, v:[], error:null})

    const dateHead = (date) => {
        var d = new Date(date)
        var options = { day: '2-digit', month: 'numeric', year: 'numeric'};
        return d.toLocaleDateString('sr-latn', options)
    }

    useEffect(() => {
        const onAttributesResponse = (data, error) => {
            if (error) {
                setErrorMsg({status:1, error:error})
            } else {
                setBerryKinds({status: 1, v:data.berry_kinds ? data.berry_kinds : [], error: null})
                var berryOpts = []
                if (data.berry_kinds) {
                    data.berry_kinds.forEach(bk => berryOpts.push({value: bk.id, label: bk.name}))
                }
                setBerryKindOpts({status: 1, v:berryOpts})
            }
        }
        props.backend.GetAttributes(onAttributesResponse, {attributes: ["farms", "berry_kinds", "crate_kinds"]})
    }, [props.backend])
    
    const updateFilter = (berry_kind) => {
        const selectedBerryKind = berryKinds.v.find(bk => bk.id === berry_kind.value)

        setReports({status:0, v:[], error:null})
        props.backend.ShareListDaily((data, error) => {
            if (error) {
                setReports({status:1, v:[], error:null})
                setErrorMsg({status:1, error:error})
            } else {
                setReports({status:1, v:data.daily_reports ? data.daily_reports : [], error:null})
            }
        }, selectedBerryKind)
    }

    return (
        <>
        <Nav isMain={false} subtitle="Izveštaji" {...props} />
        <StockMenu {...props} section="flow" />
        
        {(reports.status === 0 || berryKinds.status === 0) && <div className="loadingOverlay"></div>}
        {berryKinds.status === 1 && !errorMsg.error && 
        <div className="content">
            <div className='inputForm'>
                <form>
                <label htmlFor="berry_kind_list">Sorta:
                <Select name="berry_kind_list" isSearchable={false} options={berryKindOpts.v} defaultValue="--" onChange={updateFilter} />
                </label>
                </form>
            </div>
            <hr />
            <p className="contentSubtitle">Promet po danima sa udelima:</p><br />
                {reports.status === 1 && reports.v.length > 0 && reports.v.map(dr => {
                return (
                    <div key={dr.day}>
                    Na dan {dateHead(dr.day)}, prikaz {dr.is_stable ? <span className="positive">JE KONAČAN</span> : <span className="negative">NIJE KONAČAN</span>}<br />
                    <div className="hscrollcontainer">
                    <table key={dr.day}>
                        <thead>
                        <tr>
                            <th align="center" colSpan={6}><b>Ulaz</b></th>
                        </tr>
                        <tr>
                            <th align='center' rowSpan={2}>Zasad</th>
                            <th align="center" colSpan={2}>Početno stanje</th>
                            <th align="center" rowSpan={2}>Uneto ( kg )<br />Neto2</th>
                            <th align="center" colSpan={2}>Konačno stanje</th>
                        </tr>
                        <tr>
                            <th>Neto1 ( kg )</th>
                            <th>Udeo ( % )</th>
                            <th>Neto1+Neto2 ( kg )</th>
                            <th>Udeo ( % )</th>
                        </tr>
                        </thead>
                        <tbody>
                        {dr.starting_farm_positions.positions.map((sfp, i) => {
                            return (
                                <tr key={"sfp-"+i}>
                                    <td align="center">{sfp.farm.name}</td>
                                    {/* Pocetno stanje */}
                                    <td align="right">{Number(sfp.net_weight).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}&nbsp;</td>{/* Neto-1 */}
                                    <td align="right">{(Number(sfp.share_pct)*100.00).toFixed(2)}&nbsp;</td>{/* Udeo */}
                                    {/* Uneto */}
                                    <td align="right">{Number(dr.harvest_farm_positions.positions[i].net_weight).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}&nbsp; </td>{/* Neto-2 */}
                                    {/* Konacno stanje */}
                                    <td align="right">{Number(dr.input_farm_positions.positions[i].net_weight).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}&nbsp; </td>{/* Neto-1 + Neto-2 */}
                                    <td align="right" style={{color:"red"}}>{(Number(dr.input_farm_positions.positions[i].share_pct)*100.00).toFixed(2)}&nbsp;</td>{/* Udeo */}
                                </tr>
                            )
                        })}
                        <tr>
                            <td colSpan={4} align='right'><b>Ukupno:</b>&nbsp;</td>
                            <td align='right' style={{color: "red"}}>{Number(dr.total_input_net_weight).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}&nbsp;</td>
                            <td align='right' style={{color: "red"}}>100.00&nbsp;</td>
                        </tr>
                        </tbody>
                        <thead>
                        <tr>
                            <th colSpan={6}><b>Izlaz</b></th>
                        </tr>
                        <tr>
                            <th>Cena ( rsd )</th>
                            <th>Neto ( kg )</th>
                            <th>Prihod ( rsd )</th>
                            <th colSpan={3}>-&nbsp;-</th>
                        </tr>
                        </thead>
                        <tbody>
                        {dr.income_price_positions.positions.map((ipp, i) => {
                            return (
                                <tr key={"ipp-"+i}>
                                    <td align='right'>{Number(ipp.price).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}&nbsp;</td>
                                    <td align='right'>{Number(ipp.net_weight).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}&nbsp;</td>
                                    <td align='right'>{Number(ipp.income).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}&nbsp;</td>
                                    {/* Only on first !!! */}
                                    {i === 0 &&
                                    <td align='center' colSpan={3} rowSpan={dr.income_price_positions.positions.length+1}>
                                        Prosečna cena po kg: <b>{Number(dr.avg_price_per_kg).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</b>, 
                                        Kalo: <b>{Number(dr.total_kalo).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</b> kg</td>
                                    }
                                </tr>
                            )
                        })}   
                            <tr>
                                <td align='right'><b>Ukupno:</b></td>
                                <td align='right' style={{color: "red"}}><b>{Number(dr.total_output_net_weight).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</b>&nbsp;</td>
                                <td align='right' style={{color: "red"}}><b>{Number(dr.total_income).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</b>&nbsp;</td>
                            </tr>
                        </tbody>
                        <thead>
                        <tr>
                            <th colSpan={6}><b>Saldo</b></th>
                        </tr>
                        <tr>
                            <th align='center' rowSpan={2}>Zasad</th>
                            <th align="center" colSpan={2}>Prodato</th>
                            <th align="center" colSpan={3}>Preostalo</th>
                        </tr>
                        <tr>
                            <th>Neto udeo ( kg )</th>
                            <th>Prihod</th>
                            <th>Neto ( kg )</th>
                            <th>Neto udeo ( kg )</th>
                            <th>Udeo ( % )</th>
                        </tr>
                        </thead>
                        <tbody>
                        {dr.income_farm_positions.positions.map((ifp, i) => {
                            return (
                                <tr key={"ifp-"+i}>
                                    <td align='center'>{ifp.farm.name}</td>
                                    <td align='right'>{Number(ifp.net_weight).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}&nbsp;</td>
                                    <td align='right'>{Number(ifp.income).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}&nbsp;</td>
                                    {/* only on first */}
                                    {i === 0 &&
                                    <td align='center' rowSpan={dr.income_farm_positions.positions.length}>{Number(dr.total_remaining_weight).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}&nbsp;</td>
                                    }
                                    {/* on remaininig */}
                                    <td align='right' >{Number(dr.remaining_farm_positions.positions[i].net_weight).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}&nbsp;</td>
                                    <td align='right' >{(Number(dr.remaining_farm_positions.positions[i].share_pct)*100.00).toFixed(2)}&nbsp;</td>
                                </tr>
                            )
                        })} 
                        </tbody>
                    </table>
                    </div>
                    <br /><br />
                    <hr /><br /><br />
                    </div>
                )
            })}
            </div>
        }
        </>
    )
}

export default StockTraffic
