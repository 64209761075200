import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";

function PrintDelivery(props) {
    let { did } = useParams()
    const [delivery, setDelivery] = useState({status: 0, v:{}, error:null})
    const [summary, setSummary] = useState({status: 0, v:[]})
    const [totals, setTotals] = useState({status: 0, v:{}})

    const dateHead = (date) => {
        var d = new Date(date)
        var options = { day: '2-digit', month: '2-digit', year: 'numeric'};
        return d.toLocaleDateString('sr-latn', options)
    }

    useEffect(() => {
        const onGetResponse = (data, error) => {
            if (error) {
                setDelivery({status: 1, v:{}, error: error})
            } else {
                const sums = computeSummary(data)
                setDelivery({status: 1, v:data, error: null})
                setSummary({status: 1, v:sums})
                setTotals({status:1, v:computeTotals(sums)})
            }
        }
        props.backend.DeliveryGet(onGetResponse, did)
    }, [props.backend])

    const computeSummary = (data) => {
        const byBerryKind = new Map();
        if (data.pallets && data.pallets.length > 0) {
            data.pallets.forEach(p => {
                if (p.rejected) {
                    return
                }
                let pallet_gross_weight = data.status.name === "Rezervisano" && p.status.name === "REZERVISANO" ? Number(p.gross_weight_on_entry) : Number(p.gross_weight_on_exit)
                let pallet_net_weight = data.status.name === "Rezervisano" && p.status.name === "REZERVISANO" ? Number(p.net_weight_on_entry) : Number(p.net_weight_on_exit)
                var cur = byBerryKind.get(p.berry_kind.name)
                if (!cur) {
                    cur = {}
                    cur["berry_kind_name"] = p.berry_kind.name
                    cur["num_pallets"] = 1
                    cur["pallet_weight"] = Number(p.pallet_weight)
                    cur["gross_weight_on_exit"] = pallet_gross_weight
                    cur["net_weight_on_exit"] = pallet_net_weight
                    cur["boxes"] = p.num_crates
                    cur["plastic"] = p.num_crates * p.crate_kind.weight
                    byBerryKind.set(p.berry_kind.name, cur)
                } else {
                    cur["num_pallets"] += 1
                    cur["pallet_weight"] += Number(p.pallet_weight)
                    cur["gross_weight_on_exit"] += pallet_gross_weight
                    cur["net_weight_on_exit"] += pallet_net_weight
                    cur["boxes"] += p.num_crates
                    cur["plastic"] += p.num_crates * p.crate_kind.weight
                }
            })
        }

        let res = Array.from(byBerryKind.values())
        res.sort((a,b) => a.berry_kind_name - b.berry_kind_name);
        return res
    }

    const computeTotals = (sums) => {
        var num_pallets = 0.0
        var boxes = 0.0
        var plastic = 0.0
        var pallet_weight = 0.0
        var gross_weight_on_exit = 0.0
        var net_weight_on_exit = 0.0
        sums.forEach(s => {
            num_pallets += s.num_pallets
            boxes += s.boxes
            plastic += s.plastic
            pallet_weight += s.pallet_weight
            gross_weight_on_exit += s.gross_weight_on_exit
            net_weight_on_exit += s.net_weight_on_exit
        })
        return {
            num_pallets: num_pallets,
            boxes: boxes,
            plastic: plastic,
            pallet_weight: pallet_weight,
            gross_weight_on_exit: gross_weight_on_exit,
            net_weight_on_exit: net_weight_on_exit
        }
    }

    return (
        <>
        {(delivery.status === 0 || summary.status === 0 || totals.status === 0) && <div className="loadingOverlay"></div>}
        {delivery.status === 1 && summary.status === 1 && totals.status === 1 &&
        <div className="content print">
            <p className="contentSubtitle">PACKING LIST - {delivery.v.name}</p><br />
            <div>
                <table className='simpletable'>
                    <tbody>
                        <tr><td>Date: {dateHead(delivery.v.close_date)}</td></tr>
                        <tr><td>Truck number:</td></tr>
                    </tbody>
                </table>
            </div><br />
            <div>
            <table className='simpletable'>
                <thead>
                <tr>
                    <th align="center" >Pallet<br />no.</th>
                    <th align="center" >Pallet<br />code</th>
                    <th align="center" >Berry</th>
                    <th align="center" >Pallet<br />( kg )</th>
                    <th align="center" >Boxes</th>
                    <th align="center" >Box plastic<br />( kg )</th>
                    <th align="center" >Gross Weight<br />( kg )</th>
                    <th align="center" >Net Weight<br />( kg )</th>
                    <th align="center" >Field code</th>
                </tr>
                </thead>
                <tbody>
                {delivery.v.pallets.length === 0 &&
                    <tr><td colSpan="8">No pallets</td></tr>
                }
                {delivery.v.pallets.length > 0 && delivery.v.pallets.map((p, idx) => { 
                    let pallet_gross_weight = delivery.v.status.name === "Rezervisano" && p.status.name === "REZERVISANO" ? Number(p.gross_weight_on_entry) : Number(p.gross_weight_on_exit)
                    let pallet_net_weight = delivery.v.status.name === "Rezervisano" && p.status.name === "REZERVISANO" ? Number(p.net_weight_on_entry) : Number(p.net_weight_on_exit)
                    return (
                        <tr key={p.id}>
                        <td align="center">{idx+1}</td>
                        <td align="center">{p.name}</td>
                        <td align="center">{p.berry_kind.name}</td>
                        <td align="center">{Number(p.pallet_weight).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                        <td align="center">{p.num_crates}</td>
                        <td align="center">{Number(p.num_crates * p.crate_kind.weight).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                        <td align="center">{Number(pallet_gross_weight).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                        <td align="center">{Number(pallet_net_weight).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                        <td>&nbsp;</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
            </div><br />
            <p className="contentSubtitle">Total by berry</p><br />
            <div>
            <table className='simpletable'>
                <thead>
                <tr>
                    <th align="center" >Berry</th>
                    <th align="center" >Num pallets</th>
                    <th align="center" >Boxes</th>
                    <th align="center" >Box plastic<br />( kg )</th>
                    <th align="center" >Pallet<br />( kg )</th>
                    <th align="center" >Gross<br />( kg )</th>
                    <th align="center" >Net<br />( kg )</th>
                </tr>
                </thead>
                <tbody>
                    {summary.v.map((bs, idx) => { return (
                        <tr key={idx}>
                            <td align="center">{bs.berry_kind_name}</td>
                            <td align="center">{bs.num_pallets}</td>
                            <td align="center">{bs.boxes}</td>
                            <td align="center">{bs.plastic.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                            <td align="center">{bs.pallet_weight.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                            <td align="center">{bs.gross_weight_on_exit.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                            <td align="center">{bs.net_weight_on_exit.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                        </tr>
                    )})}
                </tbody>
            </table>
            </div><br />
            <p className="contentSubtitle">Total</p><br />
            <div>
            <table className='simpletable'>
                <thead>
                <tr>
                    <th align="center" >Num pallets</th>
                    <th align="center" >Boxes</th>
                    <th align="center" >Box plastic<br />( kg )</th>
                    <th align="center" >Pallet<br />( kg )</th>
                    <th align="center" >Gross<br />( kg )</th>
                    <th align="center" >Net<br />( kg )</th>
                </tr>
                </thead>
                <tbody>
                    <tr>
                        <td align="center">{totals.v.num_pallets}</td>
                        <td align="center">{totals.v.boxes}</td>
                        <td align="center">{totals.v.plastic.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                        <td align="center">{totals.v.pallet_weight.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                        <td align="center">{totals.v.gross_weight_on_exit.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                        <td align="center">{totals.v.net_weight_on_exit.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                    </tr>
                </tbody>
            </table>
            </div>
        </div>
        }
        </>
    )
}

export default PrintDelivery
