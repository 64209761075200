import React, { useEffect, useState } from 'react'

import Nav from '../../components/Nav'
import StockMenu from '../../components/StockMenu'

function StockAudit(props) {
    const [errorMsg, setErrorMsg] = useState({status: 1, error:null})
    const [audit, setAudit] = useState({status: 0, v:[], error:null})
    
    const dateHead = (date) => {
        var d = new Date(date)
        var options = { day: '2-digit', month: 'numeric', year: 'numeric'};
        return d.toLocaleDateString('sr-latn', options)
    }

    const printTime = (date) => {
        var d = new Date(date)
        var options = { hour:'2-digit', minute: '2-digit'};
        return d.toLocaleTimeString('sr-latn', options)
    }

    useEffect(() => {
        const onListResponse = (data, error) => {
            if (error) {
                setErrorMsg({status:1, error:error})
            } else {
                setAudit({status:1, v: data.pallet_history ? data.pallet_history : [], error:null})
            }
        }
        props.backend.AuditList(onListResponse)
    }, [props.backend])

    return (
        <>
        <Nav isMain={false} subtitle="Izveštaji" {...props} />
        <StockMenu {...props} section="audit" />
        
        {audit.status === 0 && <div className="loadingOverlay"></div>}
        {audit.status === 1 && !errorMsg.error && 
        <div className="content">
            <p className="contentSubtitle">Spisak revizija na paletama:</p><br />
            <div className="hscrollcontainer">
            <table>
                <thead>
                <tr>
                    <th align="center" >Datum</th>
                    <th align="center" >Oznaka<br />palete</th>
                    <th align="center" >Bruto<br />( kg )</th>
                    <th align="center" >Neto<br />( kg )</th>
                    <th align="center" >Broj<br />gajbica</th>
                    <th align="center" >Puna<br />paleta?</th>
                    <th align="center" >Tip<br />gajbica</th>
                    <th align="center" >Sorta</th>
                    <th align="center" >Broj<br />odbijanja</th>
                    <th align="center" >Trajno<br />odbijeno</th>
                </tr>
                </thead>
                <tbody>
                {audit.v.length === 0 &&
                    <tr><td colSpan="10">Nema zabeleženih izmena</td></tr>
                }
                {audit.v.length > 0 && audit.v.map(a => {
                    return (
                        <tr key={a.id}>
                        <td align="center">{dateHead(a.created_at)}<br />{printTime(a.created_at)}</td>
                        <td align="center"><b>{a.pallet.name}</b></td>
                        <td align="center">{a.old_gross_weight !== a.new_gross_weight ?
                        a.old_gross_weight.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})+" -> "+a.new_gross_weight.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) : "-"}</td>
                        <td align="center">{a.old_net_weight !== a.new_net_weight ?
                        a.old_net_weight.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})+" -> "+a.new_net_weight.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) : "-"}</td>
                        <td align="center">{a.old_crate_count !== a.new_crate_count ?
                        a.old_crate_count+" -> "+a.new_crate_count : "-"}</td>
                        <td align="center">{a.old_is_full !== a.new_is_full ?
                        "Ne -> Da" : "-"}</td>
                        <td align="center">{a.old_crate_kind !== a.new_crate_kind ?
                        a.old_crate_kind+" -> "+a.new_crate_kind : "-"}</td>
                        <td align="center">{a.old_berry_kind !== a.new_berry_kind ?
                        a.old_berry_kind+" -> "+a.new_berry_kind : "-"}</td>
                        <td align="center">{a.old_reject_count !== a.new_reject_count ?
                        a.old_reject_count + " -> "+a.new_reject_count : "-"}</td>
                        <td align="center">{a.permanently_rejected ? "DA" : "-"}</td>
                        {/*<td align="right">{e.summary.gross_weight.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})} &nbsp;</td>*/}
                        </tr>
                    )
                })}
                </tbody>
            </table>
            </div>
        </div>
        }
        </>
    )
}

export default StockAudit
