import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";

import { Link } from 'react-router-dom'

import Nav from '../../components/Nav'
import ActionButton from '../../components/ActionButton';
import PrintIcon from '@mui/icons-material/Print';


function DeliveryView(props) {
    let { did } = useParams()
    const [delivery, setDelivery] = useState({status: 0, v:{}, error:null})
    const [summary, setSummary] = useState({status: 0, v:[]})
    const [docUploading, setDocUploading] = useState(null)
    const [loadTs, setLoadTs] = useState(0)

    const dateHead = (date) => {
        var d = new Date(date)
        var options = { day: '2-digit', month: 'numeric', year: 'numeric'};
        return d.toLocaleDateString('sr-latn', options)
    }

    const printTime = (date) => {
        var d = new Date(date)
        var options = { hour:'2-digit', minute: '2-digit'};
        return d.toLocaleTimeString('sr-latn', options)
    }

    const calculateSummary = (data) => {
        const byBerryKind = new Map();
        if (data.pallets && data.pallets.length > 0) {
            data.pallets.forEach(p => {
                if (p.status.name === "NA STANJU" || p.status.name === "ODBIJENO" || p.status.name === "REZERVISANO") {
                    return
                }
                var cur = byBerryKind.get(p.berry_kind.name)
                if (!cur) {
                    cur = {}
                    cur["berry_kind_name"] = p.berry_kind.name
                    cur["num_pallets"] = 1
                    cur["gross_weight_on_exit"] = Number(p.gross_weight_on_exit)
                    cur["net_weight_on_entry"] = Number(p.net_weight_on_entry)
                    cur["net_weight_on_exit"] = Number(p.net_weight_on_exit)
                    byBerryKind.set(p.berry_kind.name, cur)
                } else {
                    cur["num_pallets"] += 1
                    cur["gross_weight_on_exit"] += Number(p.gross_weight_on_exit)
                    cur["net_weight_on_entry"] += Number(p.net_weight_on_entry)
                    cur["net_weight_on_exit"] += Number(p.net_weight_on_exit)
                }
            })
        }

        let res = Array.from(byBerryKind.values())
        res.sort((a,b) => a.berry_kind_name - b.berry_kind_name); 
        return res
    }

    useEffect(() => {
        setLoadTs(Date.now())
        const onGetResponse = (data, error) => {
            if (error) {
                setDelivery({status: 1, v:{}, error: error})
            } else {
                setSummary({status: 1, v:calculateSummary(data)})
                setDelivery({status: 1, v:data, error: null})
            }
        }
        props.backend.DeliveryGet(onGetResponse, did)
    }, [props.backend])
    
    const reserve = () => {
        const onGetResponse = (data, error) => {
            if (error) {
                setDelivery({status: 1, v:{}, error: error})
            } else {
                setSummary({status: 1, v:calculateSummary(data)})
                setDelivery({status: 1, v:data, error: null})
            }
        }

        const onReserveResponse = (data, error) => {
            if (error) {
                setDelivery({status:2, v:delivery.v, error:error})
            } else {
                props.backend.DeliveryGet(onGetResponse, did)
            }
        }
        
        setDelivery({status:0, v:delivery.v, error:null})
        setSummary({status:0, v:delivery.v, error:null})
        props.backend.DeliveryReserve(onReserveResponse, did)
    }

    const onInputFileChange = (ev) => {
        var fd = new FormData();
        fd.append("file", ev.target.files[0])
        setDocUploading(1)
        setDelivery({status:0, v:delivery.v, error:null})

        props.backend.DeliveryDocUpload(() => {
            setDocUploading(null)

            props.backend.DeliveryGet((data, error) => {
                if (error) {
                    setDelivery({status: 1, v:{}, error: error})
                } else {
                    setSummary({status: 1, v:calculateSummary(data)})
                    setDelivery({status: 1, v:data, error: null})
                }
            }, did)
        }, fd, did)
    }

    //var numOffered = 0
    var numDeliveredPallets = 0
    
    return (
        <>
        <Nav isMain={false} subtitle="Isporuke" {...props} withLink="/deliveries" />
        
        {(delivery.status === 0 || summary.status === 0 || docUploading === 1 || loadTs === 0) && <div className="loadingOverlay"></div>}
        {delivery.status === 1 && summary.status === 1 && !docUploading && loadTs !== 0 &&
        <div className="content">
            <div className='headsumcontainer'>
                <table>
                    <tbody>
                    <tr>
                        <td align="left">Isporuka:</td>
                        <td align="right"><b>{delivery.v.name}</b></td>
                    </tr>
                    <tr>
                        <td align="left">Kupac:</td>
                        <td align="right">{delivery.v.buyer.name}</td>
                    </tr>
                    <tr>
                        <td align="left">Status:</td>
                        {(delivery.v.status.name === "U Pripremi" || delivery.v.status.name === "Čeka Kontrolu" || delivery.v.status.name === "Rezervisano") &&
                            <td align="right" className='incomplete'>
                                {props.user.system_role === "admin" ? <Link to={"/deliveries/edit/" + did}>{delivery.v.status.name}</Link> : delivery.v.status.name}
                            </td>
                        }
                        {delivery.v.status.name !== "U Pripremi" && delivery.v.status.name !== "Čeka Kontrolu" && delivery.v.status.name !== "Rezervisano" && <td align="right" className='complete'>{delivery.v.status.name}</td>}
                    </tr>
                    {(delivery.v.status.name === "Isporučeno" || delivery.v.status.name === "Rezervisano" || delivery.v.status.name === "Čeka Kontrolu") &&
                    <tr>
                        <td colSpan={2} align='center'>
                            <iframe title={"Delivery - "+did+"_"+loadTs} id={"printdeliveryframe-"+did+"_"+loadTs} name={"printdeliveryframe-"+did+"_"+loadTs} className='printonly' src={"/print/delivery/"+did} />
                            <span  style={{color: "blue", cursor: "pointer"}} onClick={(ev) => {
                                ev.preventDefault()
                                window.frames["printdeliveryframe-"+did+"_"+loadTs].focus();
                                window.frames["printdeliveryframe-"+did+"_"+loadTs].print();
                            }}>Packing List <PrintIcon sx={{ fontSize: "16px", color:"blue" }} /></span>
                        </td>
                    </tr>
                    }
                    <tr>
                        <td style={{borderTop: "1px solid gray"}}>Traženi ukupan broj paleta:</td>
                        <td style={{borderTop: "1px solid gray"}} align="right">{delivery.v.max_num_pallets}</td>
                    </tr>
                    {delivery.v.plans.map(p => {
                        return (
                            <tr key={p.id}>
                                <td>{p.berry_kind.name}:</td>
                                <td align="right">Do {p.max_num_pallets} paleta, {p.price} rsd/kg</td>
                            </tr>
                        )})
                    }
                    </tbody>
                </table>
            </div><br />
            <hr />
            <p className='contentSubtitle'>Priložena dokumenta:</p><br />
            <div className="hscrollcontainer" style={{maxWidth: "300px"}}>
                <table style={{width:"100%"}} id="inputArticles">
                    <thead>
                        <tr>
                            <td align="center">Datum</td>
                            <td align="center">Prilog</td>
                        </tr>
                    </thead>
                    <tbody>
                    {(!delivery.v.documents || delivery.v.documents.length === 0) &&
                    <tr><td colSpan="2">Nema priloženih dokumenata</td></tr>
                    }
                    {delivery.v.documents && delivery.v.documents.length > 0 && delivery.v.documents.map((d, idx) => {return (
                        <tr key={d.id}>
                            <td align="center">{dateHead(d.created_at)}<br />{printTime(d.created_at)}</td>
                            <td align="center"><a href={"/api/documents/deliveries/"+did+"/"+d.id}>Prilog-{idx+1}</a></td>
                        </tr>
                    )})}
                    </tbody>
                </table>
            </div>
            {props.user.system_role === "admin" &&
                <div className='inputForm'>
                <label htmlFor="document">Dodaj dokument:
                    <input type="file" id="document" name="document" onChange={onInputFileChange} />
                </label>
                </div>
            }
            <hr />
            <p className='contentSubtitle'>Trenutno stanje isporuke:</p><br />
            <div className="hscrollcontainer">
            <table>
                <thead>
                <tr>
                    <th align="center" >Sorta</th>
                    <th align="center" >Broj paleta</th>
                    <th align="center" >Bruto<br />( kg )</th>
                    <th align="center" >Neto<br />( kg )</th>
                    <th align="center" >Kalo<br />( kg )</th>
                </tr>
                </thead>
                <tbody>
                {summary.v.length === 0 && 
                    <tr><td colSpan="5">Nema isporučenih paleta</td></tr>
                }
                {summary.v.length > 0 && summary.v.map(bk => {
                    return (
                        <tr key={bk.berry_kind_name}>
                        <td align="center">{bk.berry_kind_name}</td>
                        <td align="center">{bk.num_pallets}</td>
                        <td align="center">{bk.gross_weight_on_exit.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                        <td align="center">{bk.net_weight_on_exit.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                        <td align="center">{(bk.net_weight_on_entry - bk.net_weight_on_exit).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})} ({(((bk.net_weight_on_entry - bk.net_weight_on_exit)/bk.net_weight_on_entry)*100.0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}%)</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
            </div><br />
            <hr />
            <p className="contentSubtitle">Palete:</p><br />
            {(delivery.v.status.name === "U Pripremi" || delivery.v.status.name === "Rezervisano") && props.user.system_role === "admin" &&
            <ActionButton title="Dodaj palete&nbsp;" onClick={reserve} />
            }
            
            <div className="hscrollcontainer sticky">
            <table>
                <thead>
                <tr>
                    <th align="center" >Zasad</th>
                    <th align="center" >Uneto</th>
                    <th align="center" >Datum<br />berbe</th>
                    <th align="center" >Oznaka</th>
                    <th align="center" >Sorta</th>
                    <th align="center" >Težina<br />palete</th>
                    <th align="center" >Tip<br />Gajbica</th>
                    <th align="center" >Broj<br />Gajbica</th>
                    <th align="center" >Bruto<br />( kg )</th>
                    <th align="center" >Neto<br />( kg )</th>
                    <th align="center" >Kalo<br />( kg, % )</th>
                    <th align="center" >Napomena</th>
                    <th align="center" >Status</th>
                    <th align="center" >&nbsp; - &nbsp;</th>
                    <th align="center" >&nbsp; - &nbsp;</th>
                </tr>
                </thead>
                <tbody>
                {(!delivery.v.pallets || delivery.v.pallets.length === 0) &&
                    <tr><td colSpan="5">Nema unetih paleta</td></tr>
                }
                {delivery.v.pallets && delivery.v.pallets.length > 0 && delivery.v.pallets.map((p, idx) => { 
                    if (p.status.name !== "ISPORUČENO" && p.status.name !== "ODBIJENO" && !p.rejected) {
                        //numOffered++
                    }
                    let kalo = 0.0
                    let kaloPct = 0.0
                    if (p.status.name === "ISPORUČENO") {
                        numDeliveredPallets++
                        if (p.net_weight_on_exit < p.net_weight_on_entry) {
                            kalo = p.net_weight_on_entry - p.net_weight_on_exit
                            kaloPct = (kalo/p.net_weight_on_entry)*100.0
                        }
                    }

                    return (
                        <tr key={p.id}>
                        <td align="center">{p.farm.name}</td>
                        <td align="center">{dateHead(p.entry_date)}<br />{printTime(p.entry_date)}</td>
                        <td align="center">{dateHead(p.harvest_date)}</td>
                        <td align="center">{p.name}</td>
                        <td align="center">{p.berry_kind.name}</td>
                        <td align="center">{Number(p.pallet_weight).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                        <td align="center">{p.crate_kind.name}</td>
                        <td align="center">{p.num_crates}</td>
                        <td align="center">{p.status.name === "NA STANJU" || p.status.name === "REZERVISANO"
                            ? Number(p.gross_weight_on_entry).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                            : Number(p.gross_weight_on_exit).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                        </td>
                        <td align="center">{p.status.name === "NA STANJU" || p.status.name === "REZERVISANO"
                            ? Number(p.net_weight_on_entry).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                            : Number(p.net_weight_on_exit).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                        </td>
                        <td align="center">{p.status.name === "ISPORUČENO" ?
                            ""+kalo.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})+" ("+kaloPct.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})+"%)"
                            : "-"}
                        </td>
                        <td align="center">{p.description}</td>
                        <td align="center">{p.rejected ? "ODBIJENO" : p.status.name}</td>
                        <td align="center">
                            {(p.rejected || p.status.name === "ODBIJENO" || props.user.system_role !== "admin") && "-"}
                            {(p.status.name === "NA STANJU" || p.status.name === "REZERVISANO") && !p.rejected && props.user.system_role === "admin" && <Link to={"/deliveries/pallet_measure/" + did + "/" + p.id}>Izmeri</Link>}

                            {p.status.name === "ISPORUČENO" &&
                                <span  style={{color: "blue", cursor: "pointer"}}>
                                    <iframe title={"PalletSticker - "+p.name+"_"+loadTs} id={"printframe-"+p.id+"_"+loadTs} name={"printframe-"+p.id+"_"+loadTs} className="printonly" src={"/print/pallet/"+p.id+"/"+numDeliveredPallets+"?ts="+loadTs} />
                                    <PrintIcon sx={{ fontSize: "16px", color:"blue" }} onClick={(ev) => {
                                        ev.preventDefault()
                                        window.frames["printframe-"+p.id+"_"+loadTs].focus();
                                        window.frames["printframe-"+p.id+"_"+loadTs].print();
                                    }} />
                                </span>
                            }
                        </td>
                        <td align='center'>
                            {delivery.v.status.name !== "Čeka Kontrolu" && "-"}
                            {delivery.v.status.name === "Čeka Kontrolu" && p.status.name === "ISPORUČENO" && props.user.system_role === "admin" && <Link to={"/deliveries/pallet_measure/" + did + "/" + p.id + "?QC=1"}>QC</Link>}
                        </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
            </div>    
        </div>
        }
        </>
    )
}

export default DeliveryView
