import React, { useEffect, useState } from 'react'
import Select from 'react-select'

import Nav from '../../components/Nav'
import StockMenu from '../../components/StockMenu'

function StockOverview(props) {
    const [errorMsg, setErrorMsg] = useState({status: 1, error:null})
    const [berryKinds, setBerryKinds] = useState({status: 0, v:[], error:null})
    const [stock, setStock] = useState({status: 0, v:{}, error:null})
    const [berryKindOpts, setBerryKindOpts] = useState({status:0, v:[]})
    const [berryKindDefaultOpts, setBerryKindDefaultOpts] = useState({status:0, v:[]})

    const dateHead = (date) => {
        var d = new Date(date)
        var options = { day: '2-digit', month: 'numeric', year: 'numeric'};
        return d.toLocaleDateString('sr-latn', options)
    }

    const printTime = (date) => {
        var d = new Date(date)
        var options = { hour:'2-digit', minute: '2-digit'};
        return d.toLocaleTimeString('sr-latn', options)
    }

    useEffect(() => {
        const onCurrentStockResponse = (data, error) => {
            if (error) {
                setErrorMsg({status:1, error:error})
            } else {
                setStock({status:1, v:data, error:null})
            }
        }
        const onAttributesResponse = (data, error) => {
            if (error) {
                setErrorMsg({status:1, error:error})
            } else {
                setBerryKinds({status: 1, v:data.berry_kinds ? data.berry_kinds : [], error: null})
                var berryOpts = []
                if (data.berry_kinds) {
                    data.berry_kinds.forEach(bk => berryOpts.push({value: bk.id, label: bk.name}))
                }
                setBerryKindOpts({status: 1, v:berryOpts})
                setBerryKindDefaultOpts({status: 1, v:berryOpts})
                props.backend.StockGetCurrent(onCurrentStockResponse, {berry_kinds: data.berry_kinds})
            }
        }
        props.backend.GetAttributes(onAttributesResponse, {attributes: ["farms", "berry_kinds", "crate_kinds"]})
    }, [props.backend])
    
    const updateFilter = (berry_kind_list) => {
        var newFilter = []
        berryKinds.v.forEach(bk => {
            const target = berry_kind_list.find(k => k.value === bk.id)
            if (!target) {
                return
            }
            newFilter.push(bk)
        })
        setBerryKindDefaultOpts({v:berry_kind_list})
        setStock({status:0, v:{}, error:null})
        props.backend.StockGetCurrent((data, error) => {
            if (error) {
                setErrorMsg({status:1, error:error})
            } else {
                setStock({status:1, v:data, error:null})
            }
        }, {berry_kinds: newFilter})
    }

    return (
        <>
        <Nav isMain={false} subtitle="Izveštaji" {...props} />
        <StockMenu {...props} section="overview" />
        
        {stock.status === 0 && <div className="loadingOverlay"></div>}
        {stock.status === 1 && !errorMsg.error && 
        <div className="content">
            <div className='inputForm'>
                <form>
            <label htmlFor="berry_kind_list">Sorte:
                <Select isMulti name="berry_kind_list" isSearchable={false} options={berryKindOpts.v} defaultValue={berryKindDefaultOpts.v} onChange={updateFilter} />
            </label>
            </form>
            </div>
            <hr />
            <p className="contentSubtitle">Na stanju po sortama:</p><br />
            <div className="hscrollcontainer">
            <table>
                <thead>
                <tr>
                    <th align="center" >Sorta</th>
                    <th align="center" >Broj<br />paleta</th>
                    <th align="center" >Bruto<br />( kg )</th>
                    <th align="center" >Neto<br />( kg )</th>
                </tr>
                </thead>
                <tbody>
                {(!stock.v.by_berry_kind || stock.v.by_berry_kind.length === 0) &&
                    <tr><td colSpan="4">Nema robe na stanju</td></tr>
                }
                {stock.v.by_berry_kind && stock.v.by_berry_kind.length > 0 && stock.v.by_berry_kind.map(e => { 
                    return (
                        <tr key={e.segment_key.berry_kind.id}>
                        <td align="center">{e.segment_key.berry_kind.name}</td>
                        <td align="center">{e.summary.num_pallets}</td>
                        <td align="right">{e.summary.gross_weight.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})} &nbsp;</td>
                        <td align="right">{e.summary.net_weight.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})} &nbsp;</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
            </div>
            <br />
            <hr />
            <p className="contentSubtitle">Rezervisano po sortama:</p><br />
            <div className="hscrollcontainer">
            <table>
                <thead>
                <tr>
                    <th align="center" >Sorta</th>
                    <th align="center" >Broj<br />paleta</th>
                    <th align="center" >Bruto<br />( kg )</th>
                    <th align="center" >Neto<br />( kg )</th>
                </tr>
                </thead>
                <tbody>
                {(!stock.v.reserved_by_berry_kind || stock.v.reserved_by_berry_kind.length === 0) &&
                    <tr><td colSpan="4">Nema rezervisane robe</td></tr>
                }
                {stock.v.reserved_by_berry_kind && stock.v.reserved_by_berry_kind.length > 0 && stock.v.reserved_by_berry_kind.map(e => {
                    return (
                        <tr key={e.segment_key.berry_kind.id}>
                        <td align="center">{e.segment_key.berry_kind.name}</td>
                        <td align="center">{e.summary.num_pallets}</td>
                        <td align="right">{e.summary.gross_weight.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})} &nbsp;</td>
                        <td align="right">{e.summary.net_weight.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})} &nbsp;</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
            </div>
            <br />
            <hr />
            <p className="contentSubtitle">Na stanju po zasadu i sorti:</p><br />
            <div className="hscrollcontainer">
            <table>
                <thead>
                <tr>
                    <th align="center" >Sorta</th>
                    <th align="center" >Zasad</th>
                    <th align="center" >Broj<br />paleta</th>
                    <th align="center" >Bruto<br />( kg )</th>
                    <th align="center" >Neto<br />( kg )</th>
                    <th align="center" >Udeo zasada<br />( za sortu )</th>
                </tr>
                </thead>
                <tbody>
                {(!stock.v.by_berry_kind_and_farm || stock.v.by_berry_kind_and_farm.length === 0) &&
                    <tr><td colSpan="6">Nema robe na stanju</td></tr>
                }
                {stock.v.by_berry_kind_and_farm && stock.v.by_berry_kind_and_farm.length > 0 && stock.v.by_berry_kind_and_farm.map(e => { 
                    return (
                        <tr key={e.segment_key.berry_kind.id+"-"+e.segment_key.farm.id}>
                        <td align="center">{e.segment_key.berry_kind.name}</td>
                        <td align="center">{e.segment_key.farm.name}</td>
                        <td align="center">{e.summary.num_pallets}</td>
                        <td align="right">{e.summary.gross_weight.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})} &nbsp;</td>
                        <td align="right">{e.summary.net_weight.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})} &nbsp;</td>
                        <td align="right">{(e.summary.net_share*100.0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})} %&nbsp;</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
            </div>
            <br />
            <hr />
            <p className="contentSubtitle">Palete:</p><br />
            <div className="hscrollcontainer">
            <table>
                <thead>
                <tr>
                    <th align="center" >Zasad</th>
                    <th align="center" >Uneto</th>
                    <th align="center" >Datum<br />berbe</th>
                    <th align="center" >Oznaka</th>
                    <th align="center" >Sorta</th>
                    <th align="center" >Težina<br />palete</th>
                    <th align="center" >Tip<br />Gajbica</th>
                    <th align="center" >Broj<br />Gajbica</th>
                    <th align="center" >Bruto<br />( kg )</th>
                    <th align="center" >Neto<br />( kg )</th>
                    <th align="center" >Status</th>
                    <th align="center" >Napomena</th>
                </tr>
                </thead>
                <tbody>
                {(!stock.v.pallets || stock.v.pallets.length === 0) &&
                    <tr><td colSpan="6">Nema robe na stanju</td></tr>
                }
                {stock.v.pallets && stock.v.pallets.length > 0 && stock.v.pallets.map(p => { 
                    return (
                        <tr key={p.id}>
                        <td align="center">{p.farm.name}</td>
                        <td align="center">{dateHead(p.entry_date)}<br />{printTime(p.entry_date)}</td>
                        <td align="center">{dateHead(p.harvest_date)}</td>
                        <td align="center">{p.name}</td>
                        <td align="center">{p.berry_kind.name}</td>
                        <td align="center">{p.pallet_weight}</td>
                        <td align="center">{p.crate_kind.name}</td>
                        <td align="center">{p.num_crates}</td>
                        <td align="center">{Number(p.gross_weight_on_entry).toFixed(2)}</td>
                        <td align="center">{Number(p.net_weight_on_entry).toFixed(2)}</td>
                        <td align="center">{p.status.name}</td>
                        <td align="center">{p.description}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
            </div>
        </div>
        }
        </>
    )
}

export default StockOverview
