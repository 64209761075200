import React, { useState, useEffect } from 'react'

import Nav from '../../components/Nav'
import FormHeader from '../../components/FormHeader';
import { Navigate } from 'react-router-dom';

const PalletEntryNew = (props) => {
    const [farms, setFarms] = useState({status:0, v:[], error:null})
    const [berryKinds, setBerryKinds] = useState({status:0, v:[], error:null})
    const [crateKinds, setCrateKinds] = useState({status:0, v:[], error:null})

    const [errorMsg, setErrorMsg] = useState({status: 1, error:null})
    const [newPeid, setNewPeid] = useState(null)

    useEffect(() => {
        const onAttributesResponse = (data, error) => {
            if (error) {
                setFarms({status:2, v:[], error:error})
            } else {
                setBerryKinds({status:1, v:data.berry_kinds ? data.berry_kinds : [], error:null})
                setCrateKinds({status:1, v:data.crate_kinds ? data.crate_kinds : [], error:null})
                setFarms({status:1, v:data.farms ? data.farms : [], error:null})
            }
        }
        props.backend.GetAttributes(onAttributesResponse, {attributes: ["farms", "berry_kinds", "crate_kinds"]})
    }, [props.backend])

    const onSubmit = (ev) => {
        ev.preventDefault()
        let fd = new FormData(ev.target)
        const farm_id = fd.get("farm_id")
        if (!farm_id || farm_id === "" || farm_id === "--") {
            document.getElementById("farm_id").focus()
            return;
        }

        const data = {
            farm: {id: farm_id},
            description: fd.get("description"),
        }

        setErrorMsg({status: 0, error:null})
        props.backend.PalletEntryNew(onSaveResponse, data)
    }

    const onSaveResponse = (data, error) => {
        if (error) {
            setErrorMsg({status: 1, error:error})
        } else {
            if (data.id && data.id !== "") {
                setNewPeid(data.id)
            } else {
                setErrorMsg({status: 1, error:null})
                setFarms({status: 2, v:farms.v, error:null})
            }
        }
    }

    return (
        <>
        <Nav isMain={false} subtitle="Prijem" {...props} />
        {(farms.status === 0 || berryKinds.status === 0 || crateKinds.status === 0 || errorMsg.status === 0) && <div className="loadingOverlay"></div>}
        {newPeid && <Navigate to={"/entries/"+newPeid} />}
        {farms.status === 2 && <Navigate to="/entries" />}
        {farms.status === 1 && berryKinds.status === 1 && crateKinds.status === 1 && 
        <>
            <div id="mainInput" className="inputForm">
            <FormHeader {...props} title="Novi Prijem" />
            <form method='POST' onSubmit={onSubmit}>
                <label htmlFor="farm_id">Zasad:
                <select name="farm_id" id="farm_id" defaultValue="--">
                    <option value="--">--</option>
                    {farms.status === 1 && farms.v.map((f) => 
                        <option key={f.id} value={f.id}>{f.name}</option>
                    )}
                </select>
                </label>
                <label htmlFor="description">Napomena:
                    <input id="description" type="text" className="textinput" name="description" />
                </label>
                {errorMsg.status === 1 && errorMsg.error && <div className="submitLine error">{errorMsg.error}</div>}
                <div className="submitLine">
                <input type="submit" className="button" name="submitForm" value="Dalje" />
                </div>
            </form>
            </div>
        </>
        }
        </>
    )
}

export default PalletEntryNew
