import React, { useState, useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom';
import Nav from '../../components/Nav'
import FormHeader from '../../components/FormHeader';
import { Navigate } from 'react-router-dom';

const DeliveryPalletMeasure = (props) => {
    let { did, pid } = useParams()
    const [pallet, setPallet] = useState({status:0, v:{}, error:null})
    const [errorMsg, setErrorMsg] = useState({status: 1, error:null})

    const [searchParams] = useSearchParams();
    const qc = searchParams.get("QC") && searchParams.get("QC") === "1" ? true : false;

    useEffect(() => {
        const onGetResponse = (data, error) => { 
            if (error) {
                setPallet({status:2, v:{}, error:error})
            } else {
                setPallet({status:1, v:data, error:null})
            }
        }
        props.backend.PalletGet(onGetResponse, pid)
    }, [props.backend])

    const onSubmit = (ev, isQc) => {
        ev.preventDefault()
        let fd = new FormData(ev.target)
        const inputMethod = Number(fd.get("input_method"))

        if (!isQc) {
            var gross_weight_on_exit = Number(pallet.v.gross_weight_on_entry)
            if (inputMethod === 0) {
                gross_weight_on_exit = Number(fd.get("gross_weight_on_exit").replace(/,/g, '.'))
                if (!gross_weight_on_exit || isNaN(gross_weight_on_exit)) {
                    document.getElementById("gross_weight_on_exit").focus()
                    return
                }
            } else {
                const multiplicator = inputMethod / 100.0
                const net_weight_with_kalo = Number(pallet.v.net_weight_on_entry) / (1 + multiplicator)
                gross_weight_on_exit = Number(pallet.v.pallet_weight) + (pallet.v.num_crates * pallet.v.crate_kind.weight) + net_weight_with_kalo
            }

            const data = {
                gross_weight_on_exit: Number(gross_weight_on_exit).toFixed(2).toString(),
                description: fd.get("description"),
                rejected: fd.get("rejected") === "0" ? false : true,
                permanently_rejected: fd.get("rejected") === "2" ? true : false
            }
            setErrorMsg({status: 0, error:null})
            props.backend.DeliveryPalletMeasureUpdate(onSaveResponse, did, pid, data)
        } else {
            const data = {
                description: fd.get("description"),
                rejected: fd.get("rejected") === "0" ? false : true,
                permanently_rejected: fd.get("rejected") === "2" ? true : false
            }
            setErrorMsg({status: 0, error:null})
            props.backend.DeliveryPalletMeasureUpdate(onSaveResponse, did, pid, data)
        }
    }

    const onSaveResponse = (data, error) => {
        if (error) {
            setErrorMsg({status: 1, error:error})
        } else {
            setErrorMsg({status: 1, error:null})
            setPallet({status: 2, v:pallet.v, error:null})
        }
    }

    const updateInputMethod = (ev) => {
        let newValue = Number(document.getElementById("input_method").value)
        if (newValue !== 0) {
            document.getElementById("gross_weight_on_exit").disabled = true
        } else {
            document.getElementById("gross_weight_on_exit").disabled = false
        }
    }

    return (
        <>
        <Nav isMain={false} subtitle="Isporuke" {...props} />
        {(pallet.status === 0 || errorMsg.status === 0) && <div className="loadingOverlay"></div>}
        {pallet.status === 2 && <Navigate to={"/deliveries/"+ did} />}
        {pallet.status === 1 && 
        <>
            <div id="mainInput" className="inputForm">
            <FormHeader {...props} title={!qc ? "Merenje Palete Na Isporuci" : "Izmena palete u kontroli"} />
            <form method='POST' onSubmit={(ev) => onSubmit(ev, qc)}>
                <label htmlFor="name">Oznaka:
                <input id="name" type="text" className="textinput" name="name" disabled defaultValue={pallet.v.name} />
                </label>

                {!qc &&
                <label htmlFor="input_method">Metoda unosa:
                <select name="input_method" id="input_method" defaultValue="0" onChange={(ev) => updateInputMethod(ev)}>
                <option value="0">Merenje</option>
                <option value="0.7">Fiksan kalo 0.7%</option>
                <option value="0.8">Fiksan kalo 0.8%</option>
                <option value="0.9">Fiksan kalo 0.9%</option>
                <option value="1.0">Fiksan kalo 1.0%</option>
                <option value="1.25">Fiksan kalo 1.25%</option>
                <option value="1.50">Fiksan kalo 1.50%</option>
                <option value="1.75">Fiksan kalo 1.75%</option>
                <option value="2.0">Fiksan kalo 2.0%</option>
                </select>
                </label>
                }

                {!qc &&
                <label htmlFor="gross_weight_on_exit">Bruto Težina Na Isporuci (kg):
                    <input id="gross_weight_on_exit" type="number" className="textinput" name="gross_weight_on_exit" defaultValue={pallet.v.gross_weight_on_entry} placeholder="Unesi težinu" />
                </label>
                }

                <label htmlFor="rejected">Novi Status Palete:
                {qc &&
                    <select name="rejected" id="rejected" defaultValue="2">
                    <option value="2">Trajno odbijeno nakon kontrole</option>
                    </select>
                }
                {!qc &&
                    <select name="rejected" id="rejected" defaultValue="0">
                    <option value="0">Isporučeno</option>
                    <option value="1">Odbijeno u kontroli</option>
                    <option value="2">Trajno odbijeno nakon kontrole</option>
                    </select>
                }
                </label>
                <label htmlFor="description">Napomena:
                    <input id="description" type="text" className="textinput" name="description" defaultValue={pallet.v.description}/>
                </label>

                {errorMsg.status === 1 && errorMsg.error && <div className="submitLine error">{errorMsg.error}</div>}
                <div className="submitLine">
                <input type="submit" className="button" name="submitForm" value="Snimi" />
                </div>
            </form>
            </div>
        </>
        }
        </>
    )
}

export default DeliveryPalletMeasure
